import { Link } from "react-router-dom";

const RenderRadioButtonText = ({ linkTitle, linkURL, heading, description }) => {
    return (
        <div>
            <Link className="custom-text-color" to={linkURL}>{linkTitle} {linkTitle !== '' ? '-' : null} </Link> <span>{heading}</span>
            <p className='fontWeight-400 text-muted'>
                {description}
            </p>
        </div>
    );
}

export default RenderRadioButtonText;
