import 'bs5-lightbox';
import { Col, Image, Row } from 'react-bootstrap';
import HeadingUnderline from './HeadingUnderline';
const WhyChoose = () => {
    return (
        <>
            <h5 className='text-center fontWeight-600'>Why Choose</h5>
            <HeadingUnderline />
            <Row className='mb-5'>
                <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                    <Image src="./../images/price-tag.svg" className="mb-3" />
                    <h6>Competitive Pricing</h6>
                    <p>
                        With 500+ suppliers and the purchasing power <br />
                        of 300 million members, mytravel.com can save<br />
                        you more!
                    </p>
                </Col>
                <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                    <Image src="./../images/award.svg" className="mb-3" />
                    <h6>Award-Winning Service</h6>
                    <p>
                        With 500+ suppliers and the purchasing power <br />
                        of 300 million members, mytravel.com can save<br />
                        you more!
                    </p>
                </Col>
                <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                    <Image src="./../images/worldwide.svg" className="mb-3" />
                    <h6>Worldwide Coverage</h6>
                    <p>
                        With 500+ suppliers and the purchasing power <br />
                        of 300 million members, mytravel.com can save<br />
                        you more!
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default WhyChoose;
