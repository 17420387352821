import { Alert, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const RoomList = ({ list, setSelectedRoom, selectedRoom }) => {
    return (
        <div className="room-list">
            {list && list.length > 0 ? list.map((item, key) => (
                <Card className={`mb-3 room-list-item ${Object.keys(selectedRoom).length > 0 ? item._id === selectedRoom._id ? 'active-room': '' : null}`} key={key}  onClick={(e) => setSelectedRoom(item)}>
                    <Row className="p-2">
                        <Col xs={12} md={3}>
                            {item.thumbnails && item.thumbnails.length > 0 &&
                                <Card.Img src={item.thumbnails[0]} width={150} height={130} />
                            }
                        </Col>
                        <Col xs={12} md={9}>
                            <Card.Body>
                                <h6>{item?.title}</h6>
                                <Row>
                                    {item.facilities && item.facilities.length > 0 && item.facilities.map((fac, key2) => (
                                        <Col xs={6} md={6} key={key2}>
                                            <span className="text-muted font-12">{fac?.facilityId?.title}</span>
                                        </Col>
                                    ))}
                                    <Col xs={12} md={12}>
                                        <Link to={"#"} className="custom-text-color">Room Photos and Details</Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                        {/* <div className="bg-warning ribbon">Today's best offer</div> */}
                    </Row>
                </Card>
            )) : <Alert variant="danger">Under this hotel does found any rooms.</Alert>
            }
        </div>
    );
};

export default RoomList;