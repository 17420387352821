import { Badge, Button, Card, Col, Container, FormControl, Pagination, Row } from "react-bootstrap";
import ShowOnMap from "../includes/ShowOnMap";
import Filters from "../includes/Filters";
import { FaList } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { useEffect, useState } from "react";
import Rating from "react-rating";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import MyPagination from "../dashboard/rooms/room-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const AllHotels = ({ filters, searchResults, resetFilters }) => {
    const [hotelList, setHotelList] = useState([]);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(18);
    const [destAndNameSearch, setDestAndNameSearch] = useState('');
    const [displayView, setDisplayView] = useState('gird');
    const [updatedFilterUrl, setUpdatedFilterUrl] = useState(`${API_BASE_URL}landing/all-hotels?limit=${pageLimit}&page=${selectedPageNumber}`);
    let _filterKey = '';
    const getAllHotels = () => {
        if (searchResults && Object.keys(searchResults).length > 0) {
            setHotelList(searchResults);
        } else {
            axios.get(`${API_BASE_URL}landing/all-hotels?limit=${pageLimit}&page=${selectedPageNumber}`, { headers: { 'Content-Type': 'application/json' } }).then(res => {
                if (res.status === 200) {
                    setHotelList(res.data);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        getAllHotels();
    }, [pageLimit, selectedPageNumber]);

    const handlePageChange = (pageNumber) => {
        getAllHotels();
        setSelectedPageNumber(pageNumber);
    };

    const handleDestAndNameBtnClick = () => {
        axios.get(`${API_BASE_URL}landing/all-hotels?name=${destAndNameSearch}&limit=${pageLimit}&page=${selectedPageNumber}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (res.status === 200) {
                setHotelList(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const applyFilters = async (filterKey, filterArray) => {
        if (filterArray && filterArray.length > 0) {
            const filterString = filterArray.map(item => `${filterKey}=${item}`).join('&');
            try {
                const response = await axios.get(`${updatedFilterUrl}&${filterString}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    setHotelList(response.data);
                    setUpdatedFilterUrl(`${updatedFilterUrl}&${filterString}`);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            // If filter array is empty, remove filter from URL
            const regex = new RegExp(`&${filterKey}=[^&]+`, 'g');
            setUpdatedFilterUrl(updatedFilterUrl.replace(regex, ''));
        }
        console.log("after",updatedFilterUrl)
    };

    useEffect(() => {
        if (filters) {
            if (filters.stars && filters.stars.length > 0) {
                applyFilters('rating', filters.stars);
            } else if (filters.facilities && filters.facilities.length > 0) {
                applyFilters('facilityId', filters.facilities);
            } else if (filters.propertyTypes && filters.propertyTypes.length > 0) {
                applyFilters('propertyType', filters.propertyTypes);
            } else if (filters.cities && filters.cities.length > 0) {
                applyFilters('city', filters.cities);
            } else if (filters.meals && filters.meals.length > 0) {
                applyFilters('meal', filters.meals);
            } else if (
                filters.stars.length === 0 ||
                filters.facilities.length === 0 ||
                filters.meals.length === 0 ||
                filters.cities.length === 0 ||
                filters.propertyTypes.length === 0
            ) {
                getAllHotels();
                if(filters.stars.length === 0) {
                    _filterKey = 'rating';
                } else if(filters.facilities.length === 0) {
                    _filterKey = 'facilityId';
                } else if(filters.propertyTypes.length === 0) {
                    _filterKey = 'propertyType';
                } else if(filters.cities.length === 0) {
                    _filterKey = 'city';
                } else if(filters.meals.length === 0){
                    _filterKey = 'meal';
                }
                const regex = new RegExp(`&${_filterKey}=[^&]+`, 'g');
                setUpdatedFilterUrl(updatedFilterUrl.replace(regex, ''));
            }
        }
    }, [filters]);


    const toggleView = (event, type) => {
        event.preventDefault();
        setDisplayView(type);
    }

    return (
        <Container style={{ padding: "20px 10px" }}>
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <Card className="remove-border-radius mb-4">
                        <Card.Body>
                            <p className="text-muted">Destination or Hotel Name</p>
                            <div className="d-grid gap-2">
                                <FormControl
                                    type="search"
                                    placeholder="Search..."
                                    value={destAndNameSearch}
                                    onChange={(e) => setDestAndNameSearch(e.target.value)}
                                />
                                <Button variant="danger" size="lg" className="btn-custom-color" onClick={handleDestAndNameBtnClick}>
                                    <FontAwesomeIcon icon={faSearch} /> Search
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                    <ShowOnMap />
                    <Filters />
                </Col>
                <Col xs={12} md={9}>
                    <Row>
                        <Col xs={12} md={8}>
                            <h5>Hotel: {hotelList?.totalResults} results found</h5>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={"#"} onClick={(e) => toggleView(e, 'list')}><FaList className="me-2" color={displayView === 'gird' ? "#e5e5e5" : "#F14778"} /></Link>
                                <Link to={"#"} onClick={(e) => toggleView(e, 'gird')}><BsFillGrid3X3GapFill color={displayView === 'list' ? "#e5e5e5" : "#F14778"} /></Link>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ border: "1px solid #e5e5e5", fontSize: 13, padding: 10 }} className="mt-3">
                        <ul className="all-hotels-top-filter-list">
                            <li className="all-hotels-top-filter-list-item">Popularity</li>
                            <li className="all-hotels-top-filter-list-item">Guest rating</li>
                            <li className="all-hotels-top-filter-list-item">Latest</li>
                            <li className="all-hotels-top-filter-list-item">Price: low to high</li>
                            <li className="all-hotels-top-filter-list-item">Price: high to low</li>
                        </ul>
                    </Row>

                    <Row className="mt-3 mb-3 p-0">
                        {hotelList && hotelList.results && hotelList.results.length > 0 && hotelList.results.map((item, key) => {
                            return (
                                displayView === 'gird' ?
                                    <Col xs={6} md={4} key={key} className="mb-3">
                                        <Link to={`/hotel-detail/${item?.id}`} className="text-decoration-none">
                                            <Card className="no-border-radius">
                                                <Card.Img variant="top" src={item.thumbnails && item.thumbnails.length > 0 && item.thumbnails[0]} style={{ minHeight: 200, borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
                                                <Card.Body>
                                                    <Rating
                                                        readonly
                                                        initialRating={item?.rating}
                                                        emptySymbol={<AiOutlineStar />}
                                                        fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                                    />
                                                    <Card.Title style={{ fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</Card.Title>
                                                    <p className="text-muted fontWeight-12 mb-2">
                                                        <span className="badge bg-danger">{item.rating ? item?.rating.toFixed(1) : '0.0'}/5</span>
                                                        <span> ({item.reviews ? item.reviews.length : 0} reviews)</span>
                                                    </p>
                                                    {item.priceFrom && Object.keys(item.priceFrom).length > 0 && <p className="mb-0 text-muted">From <strong> {` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong></p>}
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>
                                    :
                                    <Col xs={12} md={6}>
                                        <Link to={`/hotel-detail/${item?.id}`} className="text-decoration-none">
                                            <Card className="no-border-radius mb-2 p-0">
                                                <Card.Body className="p-0 m-0">
                                                    <Container className="p-0">
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <Card.Img variant="top" src={item.thumbnails && item.thumbnails.length > 0 && item.thumbnails[0]} style={{ height: 200, borderTopRightRadius: 0, borderTopLeftRadius: 0 }} />
                                                            </Col>
                                                            <Col xs={12} md={8} style={{ verticalAlign: 'middle', display: 'flex' }}>
                                                                <div style={{ paddingRight: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                                    <Rating
                                                                        readonly
                                                                        initialRating={item?.rating}
                                                                        emptySymbol={<AiOutlineStar />}
                                                                        fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                                                    />
                                                                    <Card.Title style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, height: "3.222rem" }}>{item.description}</Card.Title>
                                                                    <p className="text-muted fontWeight-12 mb-2">
                                                                        <span className="badge bg-danger">{item.rating ? item?.rating.toFixed(1) : '0.0'}/5</span>
                                                                        <span> ({item?.reviews?.length} reviews)</span>
                                                                    </p>
                                                                    {item.priceFrom && Object.keys(item.priceFrom).length > 0 && <p className="mb-0 text-muted">From <strong> {` ${item.priceFrom.currencySymbol}${item.priceFrom.price}`}</strong></p>}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>

                            );
                        })}
                    </Row>

                    <MyPagination list={hotelList?.results} totalPages={hotelList?.totalPages} page={hotelList?.page} pageLimit={pageLimit} totalResults={hotelList?.totalResults} onPageChange={handlePageChange} />
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    filters: state.landingPagesInitialState.filters,
    searchResults: state.landingPagesInitialState.searchResults
});

const dispatchStateToProps = (dispatch) => ({
    resetFilters: () => {
        dispatch({
            type: "RESET_FILTERS",
            payload: null
        })
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(AllHotels);