import { Button, Carousel, Col, Dropdown, FormCheck, FormControl, Image, Row, Table } from "react-bootstrap";
import { BiSolidSortAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import Add from "./add";
import { API_REQUEST_HEADER, DELETE_BOOKING_ENDPOINT, DELETE_ROOM_ENDPOINT, GET_BOOKNGS_ENDPOINT } from "../../../endpoints";
import MyPagination from "../rooms/room-pagination";
import axios from "axios";
import { capitalizeFirstLetter, getFormattedDate } from "../../../helpers/common-helper";
import Loading from "../../loading";
import EditModal from "./edit";
import ConfirmDelete from "./confirm-delete";

export default function Booking() {
    const [loading, setLoading] = useState(true);
    const [tabActiveKey, setTabActiveKey] = useState('allBookings');
    const [showAddModal, setAddShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showEditModal, setEditShowModal] = useState(false);
    const [bookingList, setBookingList] = useState({});
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(5);
    const [filterStatus, setFilterStatus] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [selectedObjForUpdate, setSelectedObjForUpdate] = useState({});
    const [selectedBookingIDforDelete, setSelectedBookingIDForDelete] = useState(null);

    useEffect(() => {
        fetchApiData(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit, showEditModal, showAddModal]);

    const fetchApiData = (userId, limit, page) => {
        setLoading(true);
        axios.get(GET_BOOKNGS_ENDPOINT(userId, limit, page), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setBookingList(response.data);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const handlePageChange = (pageNumber) => {
        fetchApiData(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    const confrimDeleteBooking = (id) => {
        setSelectedBookingIDForDelete(id);
        setShowConfirmModal(true);
    }

    const onUpdateStatusClick = (obj) => {
        setSelectedObjForUpdate(obj);
        setEditShowModal(true);
    }

    const deleteBooking = () => {
        axios.delete(`${DELETE_BOOKING_ENDPOINT}/${selectedBookingIDforDelete}`, API_REQUEST_HEADER()).then((response) => {
            if (response.status === 204) {
                setShowConfirmModal(false);
                fetchApiData(userId, pageLimit, selectedPageNumber);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    return (
        <>
            {loading ? <Loading />
                :
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={7}>
                            <ul className="guest-tabs">
                                <li className={tabActiveKey === 'allBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('allBookings'); setFilterStatus('all') }}>All ({bookingList?.totalResults})</Link></li>
                                <li className={tabActiveKey === 'newBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('newBookings'); setFilterStatus('new') }}>New ({bookingList?.newBookingCount})</Link></li>
                                <li className={tabActiveKey === 'bookedBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('bookedBookings'); setFilterStatus('booked') }}>Booked ({bookingList?.bookedBookingCount})</Link></li>
                                <li className={tabActiveKey === 'pendingBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('pendingBookings'); setFilterStatus('pending') }}>Pending ({bookingList?.pendingBookingCount})</Link></li>
                                <li className={tabActiveKey === 'canceledBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('canceledBookings'); setFilterStatus('canceled') }}>Canceled ({bookingList?.canceledBookingCount})</Link></li>
                                <li className={tabActiveKey === 'refundBookings' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('refundBookings'); setFilterStatus('refund') }}>Refund ({bookingList?.refundBookingCount})</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={5} className="custom-column-style" style={{ display: "flex", justifyContent: 'flex-end', alignItems: "flex-start" }}>
                            <div className="add-room--search-container">
                                <div className="add-room--search-inner-container">
                                    <FormControl
                                        type="text"
                                        placeholder="Search here"
                                        className="make-beautify me-2 input-lg"
                                        aria-label="Search"
                                        style={{ minWidth: 300, paddingRight: 30, caretColor: '#F14778' }}
                                        value={searchText}
                                        onChange={(event) => setSearchText(event.target.value)}
                                    />
                                    <div style={{ position: "relative", right: 35, top: 0 }}>
                                        <Image src='./../images/icons/search.png' width={14} height={14} />
                                    </div>
                                </div>
                            </div>
                            <Button variant="danger" className="btn-custom-color add-new-booking-btn fontSize-14" onClick={() => setAddShowModal(true)} style={{ alignSelf: "start" }}><AiOutlinePlus /> Add New Booking</Button>
                        </Col>
                    </Row>

                    <div className="guest-tabs-contents">
                        <Table striped responsive hover style={{ backgroundColor: "white" }} border={"0px #e5e5e5"}>
                            <thead>
                                <tr>
                                    <th><FormCheck /></th>
                                    <th><div className="d-flex align-items-center">Room Name <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center"></div></th>
                                    <th><div className="d-flex align-items-center">Customer <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Bed Type <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Floor <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Facilities <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Amount <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Status <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingList && bookingList.results && bookingList.results.length > 0
                                    ? bookingList.results
                                        .filter((rm) => {
                                            const statusMatches = (
                                                (filterStatus === 'all') ||
                                                (filterStatus === 'new' && (rm.status !== null && (rm.status && rm.status.toLowerCase() === 'new'))) ||
                                                (filterStatus === 'booked' && (rm.status !== null && (rm.status && rm.status.toLowerCase() === 'booked'))) ||
                                                (filterStatus === 'pending' && (rm.status !== null && (rm.status && rm.status.toLowerCase() === 'pending'))) ||
                                                (filterStatus === 'canceled' && (rm.status !== null && (rm.status && rm.status.toLowerCase() === 'canceled'))) ||
                                                (filterStatus === 'refund' && (rm.status !== null && (rm.status && rm.status.toLowerCase() === 'refund')))
                                            );

                                            const textMatches = (
                                                (rm.roomName ? rm.roomName.toLowerCase().includes(searchText.toLowerCase()) : true) ||
                                                (rm.roomNumber ? rm.roomNumber.toLowerCase().includes(searchText.toLowerCase()) : true) ||
                                                (rm.facilities && rm.facilities.some(facility =>
                                                    facility.title ? facility.title.toLowerCase().includes(searchText.toLowerCase()) : true
                                                ))
                                            );

                                            return statusMatches && textMatches;
                                        }).map((rm, key) => (
                                            <tr key={key}>
                                                <td><FormCheck /></td>
                                                <td style={{ width: 200 }}>
                                                    {rm.thumbnails && rm.thumbnails.length > 0 ?
                                                        <Carousel indicators={false} className="roomSlider" style={{ maxWidth: 200 }}>
                                                            {rm.thumbnails.map((slide, keyy) => (
                                                                <Carousel.Item key={keyy}>
                                                                    <img
                                                                        className="d-block w-100"
                                                                        style={{ height: 100 }}
                                                                        src={slide}
                                                                        alt="Second slide"
                                                                    />
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                        :
                                                        <span className="text-muted">Undefined</span>
                                                    }
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <div className="d-flex" style={{ flexDirection: "column", marginLeft: 15 }}>
                                                        <Link to={"#"} className="custom-text-color">{rm.roomNumber ? rm.roomNumber : 'Not Assigned'}</Link>
                                                        <strong>{rm.roomName ? rm.roomName : 'Not Assigned'}</strong>
                                                    </div>
                                                </td>
                                                <td style={{ padding: 15, verticalAlign: 'middle' }}>{rm.username ? rm.username : <span className="text-muted">Undefined</span>}</td>
                                                <td style={{ padding: 15, verticalAlign: "middle" }}>
                                                    {rm.bedType ? rm.bedType : <span className="text-muted">Undefined</span>}
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>{rm.floor ? rm.floor : <span className="text-muted">Undefined</span>}</td>
                                                <td style={{ maxWidth: 200, verticalAlign: "middle" }}>
                                                    {rm.facilities && rm.facilities.length > 0 ? rm.facilities.map((fac, fkey) => (
                                                        <span key={fkey}>{fac.title + ", "}</span>
                                                    )) : <span className="text-muted">Undefined</span>}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    {rm.bookingAmount ?
                                                        <div>
                                                            <strong>{rm.currencySymbol}</strong>
                                                            <span>{rm.bookingAmount.toFixed(2)}</span>
                                                        </div>
                                                        :
                                                        <span className="text-muted">NaN</span>
                                                    }
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <span className={`text-${rm?.status.toLowerCase()}`}>{rm.status}</span>
                                                        <div className="d-flex flex-column">
                                                            {(rm.status === 'booked' || rm.status === 'Booked') &&
                                                                <span>{getFormattedDate(rm.checkIn, 'monthDay') + " - " + getFormattedDate(rm.checkOut, 'onlyDay')}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                                                <Dropdown.Item onClick={() => confrimDeleteBooking(rm._id)}>Delete</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => onUpdateStatusClick(rm)}>Update Status</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td colSpan={9} className="text-center text-danger">No record found</td></tr>}
                            </tbody>
                        </Table>
                        <MyPagination list={bookingList?.results} totalPages={bookingList?.totalPages} page={bookingList?.page} pageLimit={pageLimit} totalResults={bookingList?.totalResults} onPageChange={handlePageChange} />
                    </div>

                    <Add show={showAddModal} onHide={() => setAddShowModal(false)} />
                    <EditModal show={showEditModal} onHide={() => setEditShowModal(false)} selectedItem={selectedObjForUpdate}/>
                    <ConfirmDelete 
                        textBody="Are you sure you want to delete this booking?"
                        show={showConfirmModal} 
                        onHide={() => setShowConfirmModal(false)} 
                        delete={deleteBooking}
                    />
                </>
            }
        </>
    );
}