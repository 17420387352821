import Card from 'react-bootstrap/Card';
import RenderStar from './RenderStar';
import { Badge, Button, Col, Image, Row } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ReactRating from 'react-rating';
import { useState } from 'react';
import { AiFillStar, AiOutlineHeart, AiOutlineStar } from "react-icons/ai";
import { Link } from 'react-router-dom';

const SearchResultSingleItem = ({ imgUrl, requestFrom, item }) => {
    let minHeight = requestFrom === 'home' ? 160 : 200;
    const [rating, setRating] = useState(0);
    return (
        <Card className={requestFrom !== 'currentBookings' ? 'remove-border-radius' : 'no-border '}>
            {requestFrom !== 'offers' ?
                <Card.Img variant="top" className='test' src={imgUrl} style={requestFrom !== 'currentBookings' ? { minHeight: minHeight, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" } : { borderRadius: 25, height: minHeight }} />
                :
                item.set_image_as_background ? <Card.Img variant="top" src={imgUrl} style={{ height: minHeight }} /> : <Card className='no-border' style={{ height: minHeight }}>
                    <Row>
                        <Col md={8} xs={12}>
                            <Card.Body>
                                <div className='d-flex justify-content-center' style={{ flexDirection: "column", height: minHeight }}>
                                    <h5>{item.title}</h5>
                                    <p className="font-12">{item.description}</p>
                                    <Button variant="primary" className="btn-find-a-stay">Find a stay</Button>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card.Img variant="top" src={item.thumbnail} style={{ height: minHeight }} />
                        </Col>
                    </Row>
                </Card>
            }

            {requestFrom === 'tour' &&
                <Card.ImgOverlay style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: minHeight }}>
                    <div className='d-flex justify-content-space-between'>
                        <div style={{ color: 'white' }}>
                            {item.featured ? <Badge bg="light" text="danger" style={{ marginRight: 15 }}>Feature</Badge> : null}
                            {item.discount > 0 ? <Badge bg="light" text="danger">{item.discount_type} {item.discount}</Badge> : null}
                        </div>
                        <Link to={"#"}><AiOutlineHeart style={{ color: 'white' }} /></Link>
                    </div>
                    <div>
                        <span className='text-white'>{item.title}</span>
                        <h6 className='text-white'>{item.currency}{Number(item.price_from).toFixed(2)}</h6>
                    </div>
                </Card.ImgOverlay>
            }

            {requestFrom === 'trendingRental' &&
                <Card.ImgOverlay style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: minHeight }}>
                    <div className='d-flex justify-content-space-between'>
                        <div style={{ color: 'white' }}>
                            {item.featured ? <Badge bg="light" text="danger" style={{ marginRight: 15 }}>Feature</Badge> : null}
                            {item.discount > 0 ? <Badge bg="light" text="danger">{item.discount_type} {item.discount}</Badge> : null}
                        </div>
                        <Link to={"#"}><AiOutlineHeart style={{ color: 'white' }} /></Link>
                    </div>
                    <div>
                        <span className='text-white'>{item.title}</span>
                        <h6 className='text-white'>{item.currency}{Number(item.price_from).toFixed(2)}</h6>
                    </div>
                </Card.ImgOverlay>
            }

            {requestFrom === "offers" && item.set_image_as_background &&
                <Card.ImgOverlay style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: minHeight }}>
                    <div className="d-flex justify-content-center text-white col-md-8" style={{ flexDirection: "column", height: minHeight }}>
                        <h5>{item.title}</h5>
                        <p className="font-12">{item.description}</p>
                        <Button variant="primary" className="btn-find-a-stay">Find a stay</Button>
                    </div>
                </Card.ImgOverlay>
            }

            {requestFrom !== 'currentBookings' && requestFrom !== "offers" &&
                <Card.Body>
                    {requestFrom === 'allHotels' ? <RenderStar count={5} activeColor={"orange"} disableColor={"#e5e5e5"} /> : null}
                    {(requestFrom === 'home' || requestFrom === 'tour' || requestFrom === 'trendingRental') ? <span className='text-muted'> {(requestFrom === 'tour' || requestFrom === 'trendingRental') ? <FaMapMarkerAlt className='text-muted' /> : null} city, country</span> : null}
                    {requestFrom === 'tour' ?
                        <Card.Title style={{ fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</Card.Title> :
                        requestFrom !== 'trendingRental' ? <Card.Title style={{ fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Empire Prestige Causeway Bay</Card.Title> : null
                    }
                    {requestFrom === 'tour' ? <ReactRating
                        initialRating={rating}
                        emptySymbol={<AiOutlineStar />}
                        fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                        onChange={(newRating) => setRating(newRating)}
                    /> : null}
                    {requestFrom === 'allHotels' || requestFrom === 'similar'? <Badge pill bg="theme-color" className='me-1'>4.0 to 5.0</Badge> : null}
                    {requestFrom === 'home' ?
                        <div className='d-flex align-items-center'>
                            <RenderStar count={5} activeColor={"orange"} disableColor={"#e5e5e5"} /><span className='font-14 text-muted ml-2'>(1 review)</span>
                        </div>
                        :
                        requestFrom === 'trendingRental' ? 
                            <Row>
                                <Col xs={12} md={7}>
                                <Link className='custom-text-color'>5.0/5.0 Exceptional</Link> 
                                </Col>
                                <Col xs={12} md={5}>
                                <span className='font-12 text-muted'>(1.2 review)</span>
                                </Col>
                            </Row>
                            : 
                            <span className='font-12 text-muted'>(1 review)</span>
                    }
                    {requestFrom === 'allHotels' || requestFrom === 'similar' ? <p className='mt-2 mb-0'><span>From: </span><b>$480.00</b></p> : requestFrom !== 'trendingRental' ? <p className='text-muted mb-0'>3 hours 45 minutes</p> : null}
                    {requestFrom === 'similar' && 
                         <Row>
                            <Col xs={12} md={7}>
                                <Badge></Badge>
                            </Col>
                         </Row>
                    }
                </Card.Body>
            }

            {requestFrom === 'trendingRental' &&
                <Card.Footer style={{ backgroundColor: "white" }}>
                    <Row className="mb-2">
                        <Col xs={12} md={6}><span className='fontWeight-600'><Image src="./../images/dimention.png" /> 4800 sq ft</span></Col>
                        <Col xs={12} md={6} className="custom-col">
                            <span className="fontWeight-600">
                                <Image src="./../images/rooms.png" /> 4 Rooms
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}><span className="fontWeight-600"><Image src="./../images/bath.png" /> 2 bathrooms</span></Col>
                        <Col xs={12} md={6} className="custom-col">
                            <span style={{ textAlign: "right", fontWeight: 600 }}>
                                <Image src="./../images/bed.png" /> 6 beds
                            </span>
                        </Col>
                    </Row>
                </Card.Footer>
            }
        </Card>
    );
}

export default SearchResultSingleItem;