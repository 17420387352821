import 'bs5-lightbox';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { connect } from 'react-redux';
const RecommendedHotels = ({ recommendedHotels }) => {
    return (
        <>
            <div className='d-flex justify-content-space-between align-items-center'>
                <h5 style={{ fontWeight: 700 }}>Recommended Hotels</h5>
                <Link to="#" className='custom-text-color fontWeight-600'>
                    More <AiOutlineArrowRight />
                </Link>
            </div>

            <Row className='mb-3'>
                {recommendedHotels && recommendedHotels.length > 0 && recommendedHotels.map((item, key) => {
                    return (
                        <Col xs={6} md={3} key={key} className="mb-3">
                            <Link to={`/hotel-detail/${item._id}`} className="text-decoration-none">
                                <Card className='remove-border-radius'>
                                    <Card.Img variant="top" className='test' src={item.thumbnail} style={{ minHeight: 200, borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
                                    <Card.Body>
                                        <div className='card-title h5' style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 1, height: "1rem" }}>
                                            {item.name}
                                        </div>
                                        <span className="font-12 text-muted">({item?.reviews.length} review)</span>
                                        <p className="text-muted mb-0">3 hours 45 minutes</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
}

const mapStateToProps = (state) => ({
    recommendedHotels: state.landingPagesInitialState.recommended_hotels
});

export default connect(mapStateToProps, {})(RecommendedHotels);
