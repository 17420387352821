import { Button, Container } from "react-bootstrap";
import HomePageSearch from "../includes/HomePageSearch";
import OfferSlider from "../includes/OfferSlider";
import PopularActivities from "../includes/PopularActivities";
import RecommendedHotels from "../includes/RecommendedHotels";
import TodayTopDeals from "../includes/TodayTopDeals";
import TopDestination from "../includes/TopDestination";
import Trending from "../includes/Trending";
import TrendingRental from "../includes/TrendingRental";
import WhyChoose from "../includes/WhyChoose";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { connect } from "react-redux";

const Home = ({setLandingPagesInitialState}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getHomePage = () => {
        axios.get(`${API_BASE_URL}landing`).then(res => {
            if(res.status === 200) {
                setLandingPagesInitialState(res?.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getHomePage();
    }, [])

    return (
        <>
            <div className="home-page-slider mb-5">
                <span className="main-page-heading">Let's Explore The World Together!</span>
                <p className="main-page-sub-heading text-white mb-5">Find awesome hotel, tour, car, and activities in London</p>
                <HomePageSearch />
            </div>
            <div className="offer-top-destination-trending-section">
                <OfferSlider />
                <TopDestination />
                <Trending />
            </div>

            <div className="travel-tip-section">
                <h2 className="text-white">Travel Tips</h2>
                <p className="text-white" style={{ maxWidth: "80%", textAlign: "center" }}>Northern Ireland’s is now contingent. Britain is getting a divorce Northern Ireland is being offered a trial separation for Britain there is a one</p>
                <Button variant="outline-light" size="lg">Book Now</Button>
            </div>

            <Container style={windowWidth >= 992 ? { marginLeft: "15%", marginRight: "15%", width: "70%" } : {}}>
                <WhyChoose />
                <TodayTopDeals />
                <RecommendedHotels />
                <PopularActivities />
                <TrendingRental />
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    
});

const dispatchStateToProps = (dispatch) => ({
    setLandingPagesInitialState: (data) => {
        dispatch({
            type: "UPDATE_LANDING_PAGES_INITIAL_STATE",
            payload: data
        })
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(Home);