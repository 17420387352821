import { Col, Container, Image, Row } from "react-bootstrap";
import HeadingUnderline from "../includes/HeadingUnderline";
import { clients } from "../../appData";
import AboutPageCustomerReview from "../includes/AboutPageCustomerReview";
import { Link } from "react-router-dom";
import AboutUsPageSlider from "../includes/AboutUsPageSlider";
import { FaAngleRight } from "react-icons/fa";
import axios from "axios";
import { API_BASE_URL } from "../../endpoints";
import { useEffect, useState } from "react";

export default function AboutUs() {
    const [pageData, setPageDate] = useState({});

    const getAboutUs = () => {
        axios.get(`${API_BASE_URL}landing/about-us`, { headers: { 'Content-Type': 'application/json' } }).then(res => {
            if (res.status === 200) {
                setPageDate(res.data);
                console.log(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getAboutUs();
    }, []);
    return (
        <>
            <div className="mb-5 about-us--heading-container">
                <h2 className="mb-0 text-white">About Us</h2>
                <div>
                    <span className="text-white font-12">Home</span>
                    <span className="text-white font-12"><FaAngleRight /></span>
                    <span className="text-white font-12">About</span>
                </div>
            </div>

            <Container>
                <Row className="mb-4">
                    <Col className="text-center">
                        <h4>We’re truely dedicated to make your travel experience as much <br /> simple and fun as possible!</h4>
                        <HeadingUnderline />
                    </Col>
                </Row>
            </Container>

            <Container className="about-us--our-story-and-mission-container">
                <Row>
                    <Col xs={12} md={6}>
                        <h6>Our Story</h6>
                        {pageData && Object.keys(pageData).length > 0 && <p>{pageData?.ourStory}</p>}
                    </Col>
                    <Col xs={12} md={6}>
                        <h6>Our mission</h6>
                        {pageData && Object.keys(pageData).length > 0 && <p>{pageData?.ourMission}</p>}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        {pageData && Object.keys(pageData).length > 0 && pageData.slider && pageData.slider.length > 0 && <AboutUsPageSlider slider={pageData.slider} />}
                    </Col>
                </Row>
            </Container>

            <hr style={{ color: '#E7EAF3', margin: "20px 0px" }} />

            <Container className="about-us--why-choose-container">
                <Row className="mb-5">
                    <Col className="text-center">
                        <h4>Why Choose</h4>
                        <HeadingUnderline />
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                        <Image src="./../images/price-tag.svg" className="mb-3" />
                        <h6>Competitive Pricing</h6>
                        <p>
                            With 500+ suppliers and the purchasing power
                            of 300 million members, mytravel.com can save
                            you more!
                        </p>
                    </Col>
                    <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                        <Image src="./../images/award.svg" className="mb-3" />
                        <h6>Award-Winning Service</h6>
                        <p>
                            Travel worry-free knowing that we're here if you
                            needus, 24 hours a day
                        </p>
                    </Col>
                    <Col xs={12} md={4} className="text-center" style={{ fontSize: 12 }}>
                        <Image src="./../images/worldwide.svg" className="mb-3" />
                        <h6>Worldwide Coverage</h6>
                        <p>
                            Over 1,200,000 hotels in more than 200
                            countries and regions & flights to over 5,000
                            cities
                        </p>
                    </Col>
                </Row>
            </Container>

            <div className="mb-3">
                <div className="traveling-highlights">
                    <h2 className="text-white">Travelling Highlights</h2>
                    <p className="text-white">Your New Travelling Idea</p>
                    <Image src="./../images/play-button.png" />
                </div>
            </div>

            <div className="mb-3">
                <h5 className="text-center mb-3">Customer Review</h5>
                <HeadingUnderline />
                {pageData && Object.keys(pageData).length > 0 && pageData.customerReviews && pageData.customerReviews.length > 0 && <AboutPageCustomerReview reviews={pageData?.customerReviews} />}
            </div>

            <div className="mb-3">
                <div className="about-us--boost-container">
                    <Container>
                        <Row>
                            <Col xs={12} md={3}>
                                <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column", padding: 20 }}>
                                    <div style={{ width: 80, height: 80 }}><Image src="./../images/total-length.png" /></div>
                                    <span className="number-count">2333</span>
                                    <span className="text-white text-center">Total Length of Boats Sold Since 2009</span>
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column", padding: 20 }}>
                                    <div style={{ width: 80, height: 80 }}><Image src="./../images/compus.png" /></div>
                                    <span className="number-count">19</span>
                                    <span className="text-white text-center">Locations with Easy Access off the Deep Water Channel</span>
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column", padding: 20 }}>
                                    <div style={{ width: 80, height: 80 }}><Image src="./../images/sequre-feet.png" /></div>
                                    <span className="number-count">7921</span>
                                    <span className="text-white text-center">sq. ft. Showroom loaded with New & Used Boats</span>
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column", padding: 20 }}>
                                    <div style={{ width: 80, height: 80 }}><Image src="./../images/licence.png" /></div>
                                    <span className="number-count">30</span>
                                    <span className="text-white text-center">Number of Licensed Yachting Professionals</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="mb-3">
                <h5 className="text-center mb-4">Dedicated Team</h5>
                <HeadingUnderline />
                <Container className="about-us--dedicated-team">
                    <Row className="mb-3">
                        {pageData && Object.keys(pageData).length > 0 && pageData.dedicatedTeam && pageData.dedicatedTeam.length > 0 && pageData.dedicatedTeam  .map((client, key) => (
                            <Col xs={12} md={4} className="text-center mb-4">
                                <Image src={client.thumbnail} width={150} height={150} className="rounded-circle" />
                                <p className="mb-0">{client.name}</p>
                                <Link to={"#"} className="custom-text-color">{client.designation}</Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    );
};