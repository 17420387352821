export const hotels = [
    {
        hotel_id: 1,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 2,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 3,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 4,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 5,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 6,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 7,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 8,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 9,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 10,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 11,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 12,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 13,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 14,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 15,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 16,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 17,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 18,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    }
];

export const trending = [
    {
        hotel_id: 1,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 2,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 3,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 4,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 5,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 6,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 7,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 8,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    }
];

export const tours = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 5,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 6,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: false,
        discount: 0,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 7,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: false,
        discount: 0,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 8,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: false,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 9,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 0,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 10,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 0,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 11,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 12,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: false,
        discount: 0,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 13,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 14,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 15,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 16,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    }
];

export const tourDetailBreadcrumb = [
    {
        title: 'Home',
        url: '#',
        isActive: false
    },
    {
        title: 'Tour',
        url: '#',
        isActive: false
    },
    {
        title: '5-Day Oahu Tour: Honolulu, Pearl Harbor, & Diamond Head',
        url: '#',
        isActive: true
    }
];

export const detailBreadcrumb = [
    {
        title: 'Home',
        url: '#',
        isActive: false
    },
    {
        title: 'Tour',
        url: '#',
        isActive: false
    },
    {
        title: 'New Road Hote',
        url: '#',
        isActive: true
    }
];

export const tourDetailGallery = [
    {
        original: "./../images/tour-gallery/tour-detail-1.png",
        thumbnail: "./../images/tour-gallery/tour-detail-1.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-2.png",
        thumbnail: "./../images/tour-gallery/tour-detail-2.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-3.png",
        thumbnail: "./../images/tour-gallery/tour-detail-3.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-4.png",
        thumbnail: "./../images/tour-gallery/tour-detail-4.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-5.png",
        thumbnail: "./../images/tour-gallery/tour-detail-5.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-6.png",
        thumbnail: "./../images/tour-gallery/tour-detail-6.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-5.png",
        thumbnail: "./../images/tour-gallery/tour-detail-5.png",
    },
    {
        original: "./../images/tour-gallery/tour-detail-6.png",
        thumbnail: "./../images/tour-gallery/tour-detail-6.png",
    }
];

export const likeAlso = [
    {
        hotel_id: 1,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    },
    {
        hotel_id: 2,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-2.png'
    },
    {
        hotel_id: 3,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-3.png'
    },
    {
        hotel_id: 4,
        name: 'Empire Prestige Causeway Bay',
        from_price: 480,
        currency: '$',
        image_url: './../images/hotel-1.png'
    }
];

export const popularActivities = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
];

export const trendingRental = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    }
];

export const recommendedHotels = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    }
];

export const todayTopDetails = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 5,
        title: "Culturals Tours",
        thumbnail: './../images/stone.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 6,
        title: "Culturals Tours",
        thumbnail: './../images/kalifa.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 7,
        title: "Culturals Tours",
        thumbnail: './../images/mountain.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 8,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    }
];

export const reservationStatistic = [
    {
      "name": "01",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "02",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "03",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "04",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "05",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "06",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "07",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    },
    {
        "name": "08",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
      },
      {
        "name": "09",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
      },
      {
        "name": "10",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
      },
      {
        "name": "11",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
      },
      {
        "name": "12",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
      }
  ]

export const reservationStatisticOld = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

export const availableRoomToday = {
    datasets: [{
        data: [785],
        backgroundColor: [
            '#36A2EB',
        ],
        hoverBackgroundColor: [
            '#36A2EB'
        ]
    }]
};

export const newestBooking = [
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        date: "October 3th, 2023",
        description: "Room A-21 3-5 Person"
    },
    {
        thumbnail: "./../images/girl-2.jpg",
        name: "Samantha Humble",
        date: "October 3th, 2023",
        description: "Room A-21 3-5 Person"
    },
    {
        thumbnail: "./../images/boy-1.jpg",
        name: "Samantha Humble",
        date: "October 3th, 2023",
        description: "Room A-21 3-5 Person"
    },
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        date: "October 3th, 2023",
        description: "Room A-21 3-5 Person"
    }
];

export const latestCustomerReviews = [
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 5
    },
    {
        thumbnail: "./../images/girl-2.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 5
    },
    {
        thumbnail: "./../images/boy-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 5
    },
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 5
    }
];

export const allReviews = [
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 5,
        code: "#C01234"
    },
    {
        thumbnail: "./../images/girl-2.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 4,
        code: "#C01234"
    },
    {
        thumbnail: "./../images/boy-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 3,
        code: "#C01234"
    },
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 2,
        code: "#C01234"
    },
    {
        thumbnail: "./../images/girl-1.jpg",
        name: "Samantha Humble",
        posted_date: "26/04/2020, 12:42 AM",
        description: "Word includes two built-in paragraph styles: Normal and List Paragraph. By default, Word automatically applies the Normal paragraph style to all text in a blank, new document.",
        rating: 2,
        code: "#C01234"
    }
];

export const guests = [
    {
        thumbnail: './../images/girl-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Refund",
        statusClass: 'text-danger'
    },
    {
        thumbnail: './../images/girl-2.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Canceled",
        statusClass: 'text-muted'
    },
    {
        thumbnail: './../images/boy-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Booked",
        statusClass: 'text-success'
    },
    {
        thumbnail: './../images/girl-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Refund",
        statusClass: 'text-danger'
    },
    {
        thumbnail: './../images/girl-2.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Canceled",
        statusClass: 'text-muted'
    },
    {
        thumbnail: './../images/boy-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Booked",
        statusClass: 'text-success'
    },
    {
        thumbnail: './../images/girl-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Refund",
        statusClass: 'text-danger'
    },
    {
        thumbnail: './../images/girl-2.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Canceled",
        statusClass: 'text-muted'
    },
    {
        thumbnail: './../images/boy-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Booked",
        statusClass: 'text-success'
    },
    {
        thumbnail: './../images/boy-1.jpg',
        code: '#GS-1234',
        name: 'Yonna',
        orderDate: {
            date: 'Sunday, Oct 24th, 2020',
            time: '08:29 AM'
        },
        checkIn: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        checkOut: {
            date: 'Oct 29th, 2020',
            time: "08:29 AM"
        },
        roomType: {
            type: "Queen A-2345",
            no: "0034" 
        },
        status: "Booked",
        statusClass: 'text-success'
    }
];

export const employees = [
    {
        name: "Margaretha",
        thumbnail: "./../images/girl-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "INACTIVE",
        statusClass: "text-danger"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/boy-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "ACTIVE",
        statusClass: "text-success"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/girl-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "INACTIVE",
        statusClass: "text-danger"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/boy-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "ACTIVE",
        statusClass: "text-success"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/girl-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "INACTIVE",
        statusClass: "text-danger"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/boy-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "ACTIVE",
        statusClass: "text-success"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/girl-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "INACTIVE",
        statusClass: "text-danger"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/boy-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "ACTIVE",
        statusClass: "text-success"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/girl-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "INACTIVE",
        statusClass: "text-danger"
    },
    {
        name: "Margaretha",
        thumbnail: "./../images/boy-1.jpg",
        employee_id: "#EMP-00025",
        joining_date: "Join on January 21th, 2015",
        job_desk: "Act as a liaison between guests and any department necessary including the kitchen, housekeeping, etc",
        schedule: {
            days: "Tuesday, Friday, Sunday",
            timing: "08:00 AM - 04:00 PM",
        },
        "contact": "+12 3456 678",
        status: "ACTIVE",
        statusClass: "text-success"
    }
];

export const rooms = [
    {
        slider: ['./../images/rooms/room-1.png'],
        code: "#0004",
        title: "Deluxe B-0004",
        bedType: "Double Bed",
        roomFloor: "Floor G-05",
        roomFacilities: "AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi",
        status: {
            booked: true,
            dates: "Oct 24th - 26th",
            class: 'text-danger'
        }
    },
    {
        slider: ['./../images/rooms/room-2.png'],
        code: "#0004",
        title: "Deluxe B-0004",
        bedType: "Double Bed",
        roomFloor: "Floor G-05",
        roomFacilities: "AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi",
        status: {
            booked: true,
            dates: "Oct 24th - 26th",
            class: 'text-danger'
        }
    },
    {
        slider: ['./../images/rooms/room-3.png'],
        code: "#0004",
        title: "Deluxe B-0004",
        bedType: "Double Bed",
        roomFloor: "Floor G-05",
        roomFacilities: "AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi",
        status: {
            booked: true,
            dates: "Oct 24th - 26th",
            class: 'text-danger'
        }
    },
    {
        slider: ['./../images/rooms/room-4.png'],
        code: "#0004",
        title: "Deluxe B-0004",
        bedType: "Double Bed",
        roomFloor: "Floor G-05",
        roomFacilities: "AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi",
        status: {
            booked: true,
            dates: "Oct 24th - 26th",
            class: 'text-danger'
        }
    },
    {
        slider: ['./../images/rooms/room-5.png'],
        code: "#0004",
        title: "Deluxe B-0004",
        bedType: "Double Bed",
        roomFloor: "Floor G-05",
        roomFacilities: "AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi",
        status: {
            booked: true,
            dates: "Oct 24th - 26th",
            class: 'text-danger'
        }
    }
];

export const currentBookings = [
    {
        id: 1,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-1.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 2,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-2.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 3,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-3.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 4,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-1.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 5,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-2.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 6,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-3.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 7,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-1.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 8,
        title: "Culturals Tours",
        thumbnail: './../images/current-bookings/current-booking-hotel-2.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    },
    {
        id: 9,
        title: "Culturals Tours",
        thumbnail: './../images/paris.png',
        price_from: '4000',
        currency: "$",
        location: "Istanbul, Turkey",
        description: "5-Day Oahu Tour, Honolulu, Pearl Harbor, & Diamond Head",
        rating: 4,
        reviews_count: 1,
        time: "3 hours 45 minutes",
        featured: true,
        discount: 25,
        discount_type: "%",
        is_faviorte: false
    }
];

export const offers = [
    {
        title: "Take your longest holiday yet",
        description: "Browse properties offering long-term stays, many at reduced monthly rates.",
        thumbnail: "./images/offer-1.jpg",
        set_image_as_background: false
    },
    {
        title: "Fly away to your dream holiday",
        description: "Get inspired, compare and book flights with more flexibility",
        thumbnail: "./images/offer-2.png",
        set_image_as_background: true
    },
    {
        title: "Take your longest holiday yet",
        description: "Browse properties offering long-term stays, many at reduced monthly rates.",
        thumbnail: "./images/offer-1.jpg",
        set_image_as_background: false
    },
    {
        title: "Fly away to your dream holiday",
        description: "Get inspired, compare and book flights with more flexibility",
        thumbnail: "./images/offer-2.png",
        set_image_as_background: true
    }
];

export const clients = [
    {
        name: "David Robets",
        designation: "Founder & Director",
        thumbnail: "./../images/clients/client-1.png"
    },
    {
        name: "Augusta Silva",
        designation: "Chief Operating Officer",
        thumbnail: "./../images/clients/client-2.png"
    },
    {
        name: "Bernice Lucas",
        designation: "Director - Hotels",
        thumbnail: "./../images/clients/client-3.png"
    },
    {
        name: "Jessica Brown",
        designation: "Chief Executive",
        thumbnail: "./../images/clients/client-4.png"
    },
    {
        name: "David Jackson",
        designation: "Founder & CEO",
        thumbnail: "./../images/clients/client-5.png"
    },
    {
        name: "Kyle Martin",
        designation: "Founder & CEO",
        thumbnail: "./../images/clients/client-6.png"
    }
];

export const customers = [
    {
        name: "David Robets",
        designation: "client",
        thumbnail: "./../images/customers/customer-1.png",
        review: "This is the 3rd time I’ve used Travelo website and telling you the truth their services are always realiable and it only takes few minutes to plan and finalize"
    },
    {
        name: "David Robets",
        designation: "client",
        thumbnail: "./../images/customers/customer-1.png",
        review: "This is the 3rd time I’ve used Travelo website and telling you the truth their services are always realiable and it only takes few minutes to plan and finalize"
    },
    {
        name: "David Robets",
        designation: "client",
        thumbnail: "./../images/customers/customer-1.png",
        review: "This is the 3rd time I’ve used Travelo website and telling you the truth their services are always realiable and it only takes few minutes to plan and finalize"
    }
];

export const aboutPageMainSlider = [
    {
        thumbnail: "./../images/about-page-slider-1.jpg"
    },
    {
        thumbnail: "./../images/about-page-slider-1.jpg"
    },
    {
        thumbnail: "./../images/about-page-slider-1.jpg"
    }
];