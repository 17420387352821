import 'bs5-lightbox';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeadingUnderline from './HeadingUnderline';
import { connect } from 'react-redux';
import Rating from 'react-rating';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
const Trending = ({ trending }) => {
    return (
        <div>
            <h5 className='mb-3 text-center'>Trending</h5>
            <HeadingUnderline />
            <div className='d-flex justify-content-center mb-3'>
                <div className="d-flex flex-wrap justify-content-center">
                    <Button variant='danger' className='btn-custom-color me-2 mb-2'>Tour</Button>
                    <Button variant='light' className='me-2 mb-2'>Hotel</Button>
                    <Button variant='light' className='me-2 mb-2'>Activity</Button>
                    <Button variant='light' className='me-2 mb-2'>Rental</Button>
                    <Button variant='light' className='me-2 mb-2'>Car</Button>
                    <Button variant='light' className='me-2 mb-2'>Yacht</Button>
                </div>
            </div>
            <Row className='mb-3'>
                {trending && trending.length > 0 && trending.map((hotel, key) => (
                    key < 8 ? (
                        <Col xs={6} sm={4} md={3} key={key} className="mb-3">
                            <Link to={`/hotel-detail/${hotel._id}`} className="text-decoration-none">
                                <Card className='remove-border-radius'>
                                    <Card.Img variant="top" className='test' src={hotel.thumbnail} style={{ minHeight: 200, borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
                                    <Card.Body>
                                        <p className='mb-1 text-muted'>{hotel.city}, {hotel?.destinationId?.name}</p>
                                        <div className='card-title h5' style={{ fontSize: 14, display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', WebkitLineClamp: 3, height: "3rem" }}>
                                            {hotel.description}
                                        </div>
                                        <div className='flex'>
                                            <Rating
                                                readonly
                                                initialRating={hotel?.rating}
                                                emptySymbol={<AiOutlineStar />}
                                                fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                            />
                                            <span>  ({hotel.reviews.length}) Reviews</span>
                                        </div>
                                        <p className='mb-0 mt-1 text-muted'>3 hours 45 minutes</p>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    ) : null
                ))}
            </Row>
        </div>
    );
}

const mapStateToProps = (state) => ({
    trending: state.landingPagesInitialState.trending
});

export default connect(mapStateToProps, {})(Trending);