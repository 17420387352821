import { AWS_BUCKET_ACCESS_KEY, AWS_BUCKET_SECRET_KEY, AWS_BUCKET_NAME} from './../config/constant';
import AWS from 'aws-sdk';

export const doUpload = (selectedFile) => {
    return new Promise((resolve, reject) => {
        if (!selectedFile) {
            reject("File not selected!");
            return;
        }

        // Initialize AWS S3
        const s3 = new AWS.S3({
            accessKeyId: AWS_BUCKET_ACCESS_KEY,
            secretAccessKey: AWS_BUCKET_SECRET_KEY,
        });

        // Set the S3 bucket name
        const bucketName = AWS_BUCKET_NAME;

        // Set the key (object key) for the uploaded file
        const key = `uploads/${selectedFile.name}`;

        // Set the params for S3 upload
        const params = {
            Bucket: bucketName,
            Key: key,
            Body: selectedFile,
            ACL: 'public-read', // Adjust ACL based on your requirements
        };

        // Upload the file to S3
        s3.upload(params, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data.Location);
            }
        });
    });
};