import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button, Card, Col, Container, Image, Pagination, Row, Table } from "react-bootstrap";
import Rating from 'react-rating';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { TiTick } from 'react-icons/ti';
import { RxCrossCircled } from 'react-icons/rx';
import { allReviews } from '../../appData';
import axios from 'axios';
import { API_BASE_URL } from '../../endpoints';
import MyPagination from './rooms/room-pagination';
import { formatReviewDateTime } from '../../helpers/common-helper';

export default function Reviews() {
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [key, setKey] = useState('allReviews');
    const [rating, setRating] = useState(5);
    const [reviewsList, setReviewsList] = useState([]);

    const getReviews = (id, limit, pageNumber) => {
        axios.get(`${API_BASE_URL}reviews?userId=${id}&limit=${limit}&page=${pageNumber}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                setReviewsList(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getReviews(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit]);

    const handlePageChange = (pageNumber) => {
        getReviews(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    return (
        <div className="pt-3">
            <Card className="no-border">
                <Card.Body style={{ padding: 0 }}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        variant='underline'
                        style={{ paddingLeft: 25, paddingTop: 15 }}
                    >
                        <Tab eventKey="allReviews" title="All Reviews">
                            <div className='p-3'>
                                <Table responsive hover>
                                    <tbody>
                                        {reviewsList && reviewsList.results && reviewsList.results.length > 0 && reviewsList.results.map((item, key) => (
                                            <tr style={{ borderBottom: "1px solid #e5e5e5" }} key={key}>
                                                <td style={{ verticalAlign: "top" }}>
                                                    <Image src={item?.userId?.avatar} width={80} height={80} style={{ borderRadius: 15 }} />
                                                </td>
                                                <td className='reviews--custom-column-padding'>
                                                    <div className='d-flex' style={{ flexDirection: "column" }}>
                                                        <Link to={"#"} className='custom-text-color'>{item?.userId?.code}</Link>
                                                        <h4 className='mb-3' style={{fontWeight: 700}}>{item?.userId?.name}</h4>
                                                        <small className='text-muted'>Posted On {formatReviewDateTime(item?.reviewDate)}</small>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "top" }}>
                                                    <Rating
                                                        initialRating={item.rating}
                                                        readonly
                                                        emptySymbol={<AiOutlineStar />}
                                                        fullSymbol={<AiFillStar style={{ color: "#FFBC39" }} />}
                                                    />
                                                    <p>{item.comment}</p>
                                                </td>
                                                <td style={{ width: 150, verticalAlign: 'middle' }}>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <Button variant='success' className='me-2'><TiTick /></Button>
                                                        <Button variant='danger'><RxCrossCircled /></Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <MyPagination list={reviewsList?.results} totalPages={reviewsList?.totalPages} page={reviewsList?.page} pageLimit={pageLimit} totalResults={reviewsList?.totalResults} onPageChange={handlePageChange} />
                            </div>
                        </Tab>
                        <Tab eventKey="published" title="Published">
                            <div className='p-3'>Tab content for Profile</div>
                        </Tab>
                        <Tab eventKey="deleted" title="Deleted">
                            <div className='p-3'>Tab content for Contact</div>
                        </Tab>
                        <Tab eventKey={"other"} id="filter-tab"
                            disabled
                            title={
                                <div className='d-flex justify-content-end'>
                                    <strong className='text-dark me-5'>Sort by rate:</strong>
                                    <Rating
                                        initialRating={rating}
                                        emptySymbol={<AiOutlineStar />}
                                        fullSymbol={<AiFillStar style={{ color: "#FFBC39" }} />}
                                        onChange={(newRating) => setRating(newRating)}
                                    />
                            </div>
                            }>
                        </Tab>
                    </Tabs>

                </Card.Body>
            </Card>
        </div>
    );
}