import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../dashboard/sidebar";
import MyNavbar from "../navbar/MyNavbar";
import { Col, Row } from "react-bootstrap";

export default function DashboardLayout() {
	const location = useLocation();
	const { pathname } = location;
	let title = "Title"; // Default title if not matched

	// Define your route configurations
	const routeConfigurations = [
		{ path: "/dashboard/home", title: "Dashboard" },
		{ path: "/dashboard/reviews", title: "Reviews" },
		{ path: "/dashboard/guest-list", title: "Guest List" },
		{ path: "/dashboard/guest-detail", title: "Guest Detail" },
		{ path: "/dashboard/rooms", title: "Rooms" },
		{ path: "/dashboard/employee", title: "Employee" },
		{ path: "/dashboard/settings", title: "Settings" },
		{ path: "/dashboard/booking", title: "Booking" },
		{ path: "/dashboard/hotels", title: "Hotels" },
		{ path: "/dashboard/search-results", title: "Booking" },
		{ path: "/dashboard/search-hotel-detail", title: "Booking" },
		// Add more routes as needed
	];

	// Find the matching route and get its title
	for (const route of routeConfigurations) {
		if (pathname.startsWith(route.path)) {
			title = route.title;
			break;
		}
	}

	return (
		<>
			<Row className="remove--bs-gutter">
				<Col xs={12} md={2} className="bg-dark">
					<Sidebar />
				</Col>
				<Col xs={12} md={10} className="bg-light">
					<MyNavbar title={title} />
					<div className="container-fluid mt-3">
						<Outlet />
					</div>
				</Col>
			</Row>
		</>
		// <div style={{ display: "flex", height: "100%" }}>
		// 	<div
		// 		style={{
		// 			position: "relative",
		// 			width: "17%",
		// 			background: "black",
		// 			padding: 20,
		// 		}}
		// 		className="d-none d-lg-block"
		// 	>
		// 		<Sidebar />
		// 	</div>
		// 	<div className="dashboard--content-container bg-light">
		// 		<MyNavbar title={title} />
		// 		<Outlet />
		// 	</div>
		// </div>
	);
}
