import { Card, Image } from "react-bootstrap";

const ShowOnMap = () => {
    return (
        <Card className="remove-border-radius mb-4">
            <Card.Body className="p-0">
                <Image src="./../images/show-on-map.jpg" alt="show-on-map" className="img-fluid"/>
            </Card.Body>
        </Card>
    );
}

export default ShowOnMap;