import 'bs5-lightbox';
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import HeadingUnderline from './HeadingUnderline';
import { connect } from 'react-redux';

const TodayTopDeals = ({ deals }) => {
    const itemsPerSlide = 4;
    const numSlides = Math.ceil(deals.length / itemsPerSlide);
    return (
        <div className='mb-5'>
            <h5 className='text-center fontWeight-600'>Today's Top Deals</h5>
            <HeadingUnderline />
            <Row className='mb-3'>
                <Carousel indicators={false}>
                    {Array.from({ length: numSlides }).map((_, slideIndex) => (
                        <Carousel.Item key={slideIndex}>
                            <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <Row>
                                    {deals.slice(
                                        slideIndex * itemsPerSlide,
                                        (slideIndex + 1) * itemsPerSlide
                                    ).map((item, itemIndex) => (
                                        <Col key={itemIndex} Col xs={6} sm={4} md={3}>
                                            <a href={`/hotel-detail/${item?.hotelId?.id}`} className="text-decoration-none">
                                                <Card className='remove-border-radius'>
                                                    <Card.Img variant="top" className='test' src={item?.hotelId?.thumbnails && item?.hotelId?.thumbnails.length > 0 && item?.hotelId?.thumbnails[0]} style={{ minHeight: 200, borderTopLeftRadius: 0, borderTopRightRadius: 0 }} />
                                                    <Card.Body>
                                                        <div className='card-title h5' style={{ fontSize: 14 }}>
                                                            {item?.hotelId?.name}
                                                        </div>
                                                        <span>  ({item?.hotelId?.reviews?.length}) Reviews</span>
                                                        <p className='mb-0 mt-1 text-muted'>3 hours 45 minutes</p>
                                                    </Card.Body>
                                                </Card>
                                                {/* <SearchResultSingleItem imgUrl={item.thumbnail} requestFrom='todayTopDeals' item={item} /> */}
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
        </div>
    );
}

const mapStateToProps = (state) => ({
    deals: state.landingPagesInitialState.deals
});
export default connect(mapStateToProps, {})(TodayTopDeals);
