import { Modal } from "react-bootstrap";

const SpecialRequestModal = ({ show, onHide, selectedItem }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Special Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedItem?.specialRequest}
            </Modal.Body>
        </Modal>
    );
}

export default SpecialRequestModal;