import { Alert, Badge, Button, ButtonGroup, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, FormSelect, Image, Row } from "react-bootstrap";
import { AiFillStar, AiOutlineHeart, AiOutlineShareAlt, AiOutlineStar } from "react-icons/ai";
import ReactImageGallery from "react-image-gallery";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ShowOnMap from "./../../includes/ShowOnMap";
import axios from "axios";
import RoomList from "./../../includes/room.list";
import AverageReview from "./../../includes/average.review";
import { connect } from "react-redux";
import he from 'he';
import { API_BASE_URL, API_REQUEST_HEADER, GET_GUEST_ENDPOINT } from "../../../endpoints";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { format, parse } from "date-fns";

const HotelDetail = ({ hotelId, searchFormModal }) => {
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [hotelDetails, setHotelDetails] = useState({});
    const [hotelGallery, setHotelGallery] = useState([]);
    const [selectedDate, setSelectedDate] = useState({
        checkIn: format(new Date(), 'yyyy/MM/dd'),
        checkOut: format(new Date(), 'yyyy/MM/dd')
    });
    const [isExpandedDescription, setIsExpandedDescription] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [adultCount, setAdultCount] = useState(2);
    const [childrenCount, setChildrenCount] = useState(1);
    const [infantCount, setInfantCount] = useState(1);
    const [bookingCount, setBookingCount] = useState(1);
    const [validated, setValidated] = useState(false);
    const [userList, setUserList] = useState({});
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [apiResponse, setApiResponse] = useState({});
    const [isDateRangePickerEvent, setIsDateRangePickerEvent] = useState(false);

    const getHotelDetails = (id) => {
        const requestBody = {
            hotelId: id,
            dateRange: searchFormModal.dateRange
        };
        axios.post(`${API_BASE_URL}landing/hotel-details`, requestBody, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.status === 200) {
                setHotelDetails(response.data);
                setSelectedDate({
                    ...selectedDate,
                    checkIn: format(parse(searchFormModal?.dateRange?.formatedStart, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd'),
                    checkOut: format(parse(searchFormModal?.dateRange?.formatedEnd, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd'),
                });
                if (response.data && response.data.thumbnails && response.data.thumbnails.length > 0) {
                    const result = response.data.thumbnails.map((val, key) => {
                        return { original: val, thumbnail: val };
                    });
                    setHotelGallery(result);
                }
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getHotelDetails(hotelId);
        getUsers();
    }, [hotelId]);

    const bookNowHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateRangePickerEvent) {
            return;
        }

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        if (Object.keys(selectedRoom).length === 0) {
            alert("Choose a room first!");
            return;
        }
        const formData = {
            hotelId: hotelId,
            orderDate: new Date(),
            userId: selectedUserId,
            checkIn: selectedDate.checkIn,
            checkOut: selectedDate.checkOut,
            currencySymbol: hotelDetails && Object.keys(hotelDetails).length > 0 && hotelDetails.priceFrom.currencySymbol,
            details: [{
                roomId: Object.keys(selectedRoom).length > 0 ? selectedRoom?._id : null,
                adult: adultCount,
                children: childrenCount,
                infant: infantCount,
                quantity: bookingCount
            }],
            amount: (hotelDetails && Object.keys(hotelDetails).length > 0 && Object.keys(hotelDetails.priceFrom).length > 0) ? hotelDetails?.priceFrom?.price : 0,
            createdBy: userId
        };

        axios.post(`${API_BASE_URL}landing/booking`, formData, {
            headers: {
                'accept': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                setApiResponse({ type: "success", message: "Thanks! your booking has been completed successfully!" });
                if (userList && userList.guests && userList.guests.length > 0) {
                    setSelectedUserId(userList.guests[0]._id);
                }
                setSelectedRoom({});
                setSelectedDate({
                    ...selectedDate,
                    checkIn: format(parse(searchFormModal?.dateRange?.formatedStart, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd'),
                    checkOut: format(parse(searchFormModal?.dateRange?.formatedEnd, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd'),
                });
                setAdultCount('');
                setChildrenCount('');
                setInfantCount('');
                setBookingCount('')
            }
        }).catch(err => {
            console.log("Error in bookNowHandle ", err);
            setApiResponse({ type: "danger", err });
        })
    }

    const getUsers = () => {
        axios.get(GET_GUEST_ENDPOINT('guest', 100, 1), API_REQUEST_HEADER()).then(res => {
            if (res.status === 200) {
                setUserList(res.data);
                if (res.data && res.data.guests && res.data.guests.length > 0) {
                    setSelectedUserId(res.data.guests[0].userId);
                }
            }
        }).catch(err => {
            console.log("Error in getUser function ", err);
        });
    }

    const handleDateRangeApply = (event, picker) => {
        setIsDateRangePickerEvent(false);
        setSelectedDate({
            checkIn: picker.startDate.format('yyyy/MM/DD'),
            checkOut: picker.endDate.format('yyyy/MM/DD')
        });
    }

    return (
        <Card className="no-border-radius no-border">
            {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
            <Card.Body className="p-5">
                <Row className="mb-2">
                    <Col xs={12} md={9}>
                        <div className="d-flex justify-content-space-between">
                            <div>
                                <div className="d-flex">
                                    <h5>{hotelDetails?.name}</h5>
                                    &nbsp;&nbsp;&nbsp;
                                    <Rating
                                        readonly
                                        initialRating={hotelDetails?.rating}
                                        emptySymbol={<AiOutlineStar />}
                                        fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                    />
                                </div>
                                <div className="d-flex align-items-center" style={{ paddingLeft: 20 }}>
                                    <span className="me-2">{hotelDetails?.city}, {hotelDetails?.destinationId?.name}</span>
                                    <Link to={"#"} className="custom-text-color">View on map</Link>
                                </div>
                            </div>
                        </div>

                        <Row className="mt-3">
                            <Col xs={12} md={12}>
                                {hotelGallery && hotelGallery.length > 0 && <ReactImageGallery items={hotelGallery} />}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={12}>
                                <h5>Description</h5>
                                <p className="text-muted mb-1" style={{
                                    lineHeight: '26px',
                                    maxHeight: isExpandedDescription ? 'none' : '104px',
                                    overflow: 'hidden',
                                }}>
                                    {hotelDetails?.description}
                                </p>
                                {!isExpandedDescription ?
                                    <Link to={"#"} className="custom-text-color" onClick={(e) => setIsExpandedDescription(!isExpandedDescription)}>View More</Link>
                                    : <Link to={"#"} className="custom-text-color" onClick={(e) => setIsExpandedDescription(!isExpandedDescription)}>View Less</Link>}

                            </Col>
                        </Row>

                        <hr style={{ color: "rgba(231, 234, 243, 1)" }} />

                        <Row>
                            <Col xs={12} md={12}>
                                <h5>Select your room</h5>
                                <RoomList list={hotelDetails?.rooms} setSelectedRoom={setSelectedRoom} selectedRoom={selectedRoom} />
                            </Col>
                        </Row>

                        {hotelDetails && hotelDetails.hotelAmenities && hotelDetails.hotelAmenities.length > 0 &&
                            <>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h5>Amenities</h5>
                                    </Col>
                                    {hotelDetails && hotelDetails.hotelAmenities && hotelDetails.hotelAmenities.length > 0 && hotelDetails.hotelAmenities.map((amenitie, akey) => (
                                        <Col md={4} xs={12} key={akey}>
                                            <Image src={amenitie.amenitieId.thumbnail} className="me-2" />
                                            <label>{amenitie.amenitieId.title}</label>
                                        </Col>
                                    ))}
                                </Row>
                                <hr />
                            </>
                        }


                        {hotelDetails?.hotelNearestEssentials && hotelDetails?.hotelNearestEssentials.length > 0 &&
                            <>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h5>Nearest Essentials</h5>
                                        <Row>
                                            {hotelDetails?.hotelNearestEssentials && hotelDetails?.hotelNearestEssentials.length > 0 && hotelDetails?.hotelNearestEssentials.map((ne, nekey) => (
                                                <Col xs={12} md={4} key={nekey}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <label>{ne?.title}</label>
                                                        <span className="text-muted">{ne?.description}</span>
                                                        <Link to={"#"} className="custom-text-color">{ne?.distance} {ne?.distanceUnit}</Link>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <hr />
                            </>
                        }


                        {hotelDetails && hotelDetails?.hotelLandmarks && hotelDetails?.hotelLandmarks.length > 0 &&
                            <>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h5>What's Nearby</h5>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <label className="mb-2">Popular Landmarks</label>
                                                {hotelDetails && hotelDetails?.hotelLandmarks &&
                                                    <ul className="popular-landmark-list">
                                                        {hotelDetails?.hotelLandmarks && hotelDetails?.hotelLandmarks.length > 0 && hotelDetails?.hotelLandmarks.map((landmark, lkey) => (
                                                            landmark.landmarkId.type === 'popular' && <li key={lkey} className="pouplar-landmark-list-item">{landmark.landmarkId.title}</li>
                                                        ))}
                                                    </ul>
                                                }
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <label className="mb-2">Nearby Landmarks</label>
                                                <ul className="not-pouplar-landmark-list">
                                                    {hotelDetails?.hotelLandmarks && hotelDetails?.hotelLandmarks.length > 0 && hotelDetails?.hotelLandmarks.map((landmark, lkey) => (
                                                        landmark.landmarkId.type === 'not-popular' && <li key={lkey} className="not-pouplar-landmark-list-item">{landmark.landmarkId.title}</li>
                                                    ))}
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr />
                            </>
                        }



                        {hotelDetails?.guestPolicy && <Row>
                            <Col xs={12} md={12}>
                                <h5>Guest Policy</h5>
                                <div dangerouslySetInnerHTML={{ __html: he.decode(hotelDetails?.guestPolicy) }} />
                            </Col>
                        </Row>}

                        {hotelDetails?.childrenPolicy && <><hr /> <Row className="mb-3">
                            <Col xs={12} md={12}>
                                <h5>Children</h5>
                                <div className="children-policy" dangerouslySetInnerHTML={{ __html: he.decode(hotelDetails?.childrenPolicy) }} />
                            </Col>
                        </Row></>}

                        {hotelDetails?.extraBedsPolicy && <Row className="mb-3">
                            <Col xs={12} md={12}>
                                <h5>Extra Beds</h5>
                                <div dangerouslySetInnerHTML={{ __html: he.decode(hotelDetails?.extraBedsPolicy) }} />
                            </Col>
                        </Row>}

                        {hotelDetails?.others && <Row className="mb-3">
                            <Col xs={12} md={12}>
                                <h5>Others</h5>
                                <div dangerouslySetInnerHTML={{ __html: he.decode(hotelDetails?.others) }} />
                            </Col>
                        </Row>}

                        <hr />
                        <h5>Average Reviews</h5>
                        <AverageReview reviews={hotelDetails?.reviews} averageReviews={hotelDetails?.averageReviews} />

                        <hr />
                        <h5>Showing {hotelDetails?.reviews?.length} review</h5>
                        {hotelDetails && hotelDetails.reviews && hotelDetails.reviews.length > 0 && hotelDetails.reviews.map((review, key) => (
                            <div className='d-flex' key={key}>
                                <Image src={review?.userId?.avatar} roundedCircle width={50} height={50} />
                                <div className='d-flex' style={{ flexDirection: "column", paddingLeft: 15 }}>
                                    <label>{review?.userId.name}</label>
                                    <span className="text-muted">{review?.reviewId?.createdAt}</span>
                                    <div><Badge bg="danger" style={{ width: "auto" }}>{review.reviewId?.rating.toFixed(1)}/5</Badge> <label>The best hotel ever "</label></div>
                                    <p>{review?.reviewId?.comment}</p>
                                </div>
                            </div>
                        ))}
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className="mb-3">
                            <Col xs={12} md={12} className="d-flex mb-3">
                                <div className="me-2">
                                    <Button variant="default" className="me-2 favorite--btn"><AiOutlineHeart /></Button>
                                    <Button variant="default" className="me-2 share--btn"><AiOutlineShareAlt /></Button>
                                    <Button variant="default" className="rating--btn">{hotelDetails?.averageReviews?.rating.toFixed(1)}/5</Button>
                                </div>
                                <div className="d-flex flex-direction-column">
                                    <span>
                                        {hotelDetails?.averageReviews?.rating === 5
                                            ? 'Exceptional'
                                            : hotelDetails?.averageReviews?.rating === 4
                                                ? 'Excellent'
                                                : hotelDetails?.averageReviews?.rating === 3
                                                    ? 'Very Good'
                                                    : hotelDetails?.averageReviews?.rating === 2
                                                        ? 'Good'
                                                        : hotelDetails?.averageReviews?.rating === 1
                                                            ? 'Poor'
                                                            : 'Beginner'
                                        }
                                    </span>
                                    <span className="font-12 text-muted">({hotelDetails?.reviews?.length} Review)</span>
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <ButtonGroup aria-label="Basic example" style={{ width: "100%" }}>
                                    <Button variant="outline-dark" className="custom--tab-lowest-price my--active-tab">Lowest price include</Button>
                                    <Button variant="outline-dark" className="custom--tab-free-breakfast">Free breakfast</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header style={{ backgroundColor: "transparent" }}>
                                <div className="d-flex align-items-center"><span className="text-muted">From</span> &nbsp;
                                    <h5 className="mb-0 from-amount">
                                        {Object.keys(hotelDetails).length > 0 && hotelDetails.priceFrom && Object.keys(hotelDetails.priceFrom).length > 0 && hotelDetails.priceFrom.currencySymbol + '' + hotelDetails.priceFrom.price}
                                    </h5>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form validated={validated} noValidate onSubmit={bookNowHandle}>
                                    <FormGroup className="mb-3">
                                        <FormLabel>Users</FormLabel>
                                        <FormSelect value={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)} style={{ textAlign: 'left' }} required>
                                            <option> -- select a user -- </option>
                                            {userList && Object.keys(userList).length > 0 && userList.guests && userList.guests.map((user, key) => (
                                                <option key={key} value={user.userId}>{user.name}</option>
                                            ))}
                                        </FormSelect>
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <FormLabel className='text-muted mb-0'>Date</FormLabel>
                                        <DateRangePicker
                                            initialSettings={{ startDate: new Date(), endDate: '1/1/2050' }}
                                            onApply={handleDateRangeApply}
                                            onEvent={(event, picker) => {
                                                if (event.type === 'show' || event.type === 'showCalendar ') {
                                                    setIsDateRangePickerEvent(true);
                                                } else if (event.type === 'hide') {
                                                    setIsDateRangePickerEvent(false);
                                                }
                                            }}
                                        >
                                            <button className='form-control' style={{ textAlign: 'left' }}>
                                                <span style={{ marginLeft: 15 }} className='text-muted'>
                                                    {`${selectedDate.checkIn} - ${selectedDate.checkOut}`}
                                                </span>
                                            </button>
                                        </DateRangePicker>
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <FormLabel className="text-muted">Adults</FormLabel>
                                        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, borderBottom: '1px solid #e5e5e5' }}>
                                            <span className="label" style={{ flex: 2, fontSize: 18, fontWeight: 600 }}>Age 18+</span>
                                            <FormControl
                                                type="number"
                                                style={{ flex: 1 }}
                                                value={adultCount}
                                                onChange={(e) => setAdultCount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <FormLabel className="text-muted">Children</FormLabel>
                                        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, borderBottom: '1px solid #e5e5e5' }}>
                                            <span className="label" style={{ flex: 2, fontSize: 18, fontWeight: 600 }}>Age 6-17</span>
                                            <FormControl
                                                type="number"
                                                style={{ flex: 1 }}
                                                value={childrenCount}
                                                onChange={(e) => setChildrenCount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="mb-3">
                                        <FormLabel className="text-muted">Infant</FormLabel>
                                        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, borderBottom: '1px solid #e5e5e5' }}>
                                            <span className="label" style={{ flex: 2, fontSize: 18, fontWeight: 600 }}>Age 0-5</span>
                                            <FormControl
                                                type="number"
                                                style={{ flex: 1 }}
                                                value={infantCount}
                                                onChange={(e) => setInfantCount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </FormGroup>

                                    {hotelDetails && Object.keys(hotelDetails).length > 0 && hotelDetails.priceFrom && Object.keys(hotelDetails.priceFrom).length > 0 &&
                                        <div className="total-amount mb-3 mt-3">
                                            {`${hotelDetails.priceFrom.currencySymbol + '' + hotelDetails.priceFrom.price.toFixed(2)}`}
                                        </div>
                                    }

                                    <FormControl
                                        className="lg"
                                        type="number"
                                        style={{ width: "40%" }}
                                        value={bookingCount}
                                        onChange={(e) => setBookingCount(e.target.value)}
                                        required
                                    />

                                    <div className="d-grid gap-2 mt-3">
                                        <Button variant="primary" type="submit" className="btn-custom-color" size="lg">
                                            Book Now
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <Card className="mt-3">
                            <Card.Body>
                                <ShowOnMap />
                                <h5>Why Book With Us?</h5>
                                <ul className="why-bookus-list">
                                    <li>Better than 99% of properties in London</li>
                                    <li>Exceptional location - Inside city center</li>
                                    <li>Free Travel Insureance</li>
                                    <li>Popular neighborhood</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => ({
    hotelId: state.dashboardInitialState.bookingSearchItemSelectedId,
    searchFormModal: state.dashboardInitialState.bookingSearhFormModal
});

const dispatchStateToProps = (dispatch) => ({

})

export default connect(mapStateToProps, dispatchStateToProps)(HotelDetail);