const Logout = () => {
    // Remove items from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('tokens');
    localStorage.clear();
    // Redirect to the login screen
    window.location.href = '/login';
}

export default Logout;
