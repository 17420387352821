import { Button, Col, Dropdown, FormCheck, FormControl, Image, Row, Table } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import ReactToPrint from 'react-to-print';
import { BiSolidSortAlt } from 'react-icons/bi';
import { API_REQUEST_HEADER, DELETE_GUEST_ENDPOINT, GET_GUEST_ENDPOINT } from "../../../endpoints";
import axios from "axios";
import MyPagination from "../rooms/room-pagination";
import { AiOutlinePlus } from "react-icons/ai";
import Add from "./add";
import { cutomDateTimeFormat, formateOrderDateTime } from "../../../helpers/common-helper";
import Loading from "../../loading";

export default function GuestList() {
    const [loading, setLoading] = useState(true);
    const printableTableRef = useRef();
    const [showAddModal, setAddShowModal] = useState(false);
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [tabActiveKey, setTabActiveKey] = useState('allGuest');
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [guestList, setGuestList] = useState({});
    const [filterStatus, setFilterStatus] = useState('all');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchApiData(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit, showAddModal]);

    const fetchApiData = (userId, limit, page) => {
        setLoading(true);
        axios.get(GET_GUEST_ENDPOINT(userId, limit, page), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setGuestList(response.data);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const handlePageChange = (pageNumber) => {
        fetchApiData(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    const deleteGuest = (id) => {
        axios.delete(`${DELETE_GUEST_ENDPOINT}/${id}`, API_REQUEST_HEADER()).then((response) => {
            if (response.status === 204) {
                fetchApiData(userId, pageLimit, selectedPageNumber);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        })
    }

    return (
        <>
            {loading ? <Loading />
                :
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <ul className="guest-tabs">
                                <li className={tabActiveKey === 'allGuest' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('allGuest'); setFilterStatus('all'); }}>All Guest</Link></li>
                                <li className={tabActiveKey === 'new' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('new'); setFilterStatus('new'); }}>New</Link></li>
                                <li className={tabActiveKey === 'pending' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('pending'); setFilterStatus('pending'); }}>Pending</Link></li>
                                <li className={tabActiveKey === 'booked' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('booked'); setFilterStatus('booked'); }}>Booked</Link></li>
                                <li className={tabActiveKey === 'canceled' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('canceled'); setFilterStatus('canceled'); }}>Canceled</Link></li>
                                <li className={tabActiveKey === 'refund' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('refund'); setFilterStatus('refund'); }}>Refund</Link></li>
                            </ul>
                        </Col>

                        <Col xs={12} md={6} className="d-flex justify-content-end align-items-start">
                            <div className="add-room--search-container">
                                <div className="add-room--search-inner-container">
                                    <FormControl
                                        type="text"
                                        placeholder="Search here"
                                        className="make-beautify my-me-2 input-lg"
                                        aria-label="Search"
                                        style={{ minWidth: 350, paddingRight: 30, caretColor: '#F14778' }}
                                        value={searchText}
                                        onChange={(event) => setSearchText(event.target.value)}
                                    />
                                    <div style={{ position: "relative", right: 35, top: 0 }}>
                                        <Image src='./../images/icons/search.png' width={14} height={14} />
                                    </div>
                                </div>
                            </div>

                            <Button variant="danger" className="btn-custom-color add-new-guest-btn me-2 fontSize-12" onClick={() => setAddShowModal(true)} ><AiOutlinePlus /> Add Guest</Button>
                            <ReactToPrint
                                trigger={() => <Button variant="danger" className="btn-custom-color fontSize-12 generate-report-btn"><FaFileAlt /> Generate Report</Button>}
                                content={() => printableTableRef.current}
                            />
                        </Col>
                    </Row>

                    <div className="guest-tabs-contents">
                        <Table striped responsive hover style={{ backgroundColor: "white" }} border={"0px #e5e5e5"} ref={printableTableRef}>
                            <thead>
                                <tr>
                                    <th><FormCheck /></th>
                                    <th><div className="d-flex align-items-center">Guest <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Date Order <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Check In <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Check Out <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Special Request <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Type <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Status <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {guestList && guestList.guests && guestList.guests.length > 0
                                    ? guestList.guests
                                        .filter((guest) => {
                                            return (
                                                (
                                                    (filterStatus === 'all' ||
                                                        (filterStatus === 'new' && guest.status && guest.status.toLowerCase() === 'new') ||
                                                        (filterStatus === 'booked' && guest.status && guest.status.toLowerCase() === 'booked') ||
                                                        (filterStatus === 'pending' && guest.status && guest.status.toLowerCase() === 'pending') ||
                                                        (filterStatus === 'canceled' && guest.status && guest.status.toLowerCase() === 'canceled') ||
                                                        (filterStatus === 'refund' && guest.status && guest.status.toLowerCase() === 'refund')
                                                    )
                                                ) &&
                                                (
                                                    (guest.name && guest.name.toLowerCase().includes(searchText.toLowerCase())) ||
                                                    (guest.orderDate && guest.orderDate.toLowerCase().includes(searchText.toLowerCase())) ||
                                                    (guest.status && guest.status.toLowerCase().includes(searchText.toLowerCase())) ||
                                                    (guest.code && guest.code.toLowerCase().includes(searchText.toLowerCase()))
                                                )
                                            );
                                        })
                                        .map((guest, key) => (
                                            <tr key={key}>
                                                <td style={{ verticalAlign: "middle" }}><FormCheck /></td>
                                                <td style={{ padding: 15 }}>
                                                    <div className="d-flex align-items-center">
                                                        <Image src={guest.avatar} className="me-2" width={60} height={60} rounded />
                                                        <div className="d-flex" style={{ flexDirection: "column" }}>
                                                            <Link to={`/dashboard/guest-detail/${guest?.userId}`} className="custom-text-color">{guest.code}</Link>
                                                            <strong>{guest.name}</strong>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <strong>{formateOrderDateTime('date', guest?.orderDate)}</strong>
                                                        <small>{formateOrderDateTime('time', guest?.orderDate)}</small>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <strong>{formateOrderDateTime('date', guest?.checkIn)}</strong>
                                                        <small>{formateOrderDateTime('time', guest?.checkIn)}</small>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <strong>{formateOrderDateTime('date', guest?.checkOut)}</strong>
                                                        <small>{formateOrderDateTime('time', guest?.checkOut)}</small>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <Link to="#" className="custom-text-color">View Notes</Link>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <strong>{guest.bedType ? guest?.bedType : '-'}</strong>
                                                        {/* <small>Room No. {guest.roomType.no}</small> */}
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className={`text-${guest.status && guest?.status.toLowerCase()}`}>{guest.status ? guest?.status : '-'}</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                                                <Dropdown.Item onClick={() => deleteGuest(guest?.userId)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td colSpan={9} className="text-center text-danger">No record found</td></tr>}
                            </tbody>
                        </Table>
                        <MyPagination list={guestList?.guests} totalPages={guestList?.totalPages} page={guestList?.page} pageLimit={pageLimit} totalResults={guestList?.totalResults} onPageChange={handlePageChange} />
                    </div >

                    <Add show={showAddModal} onHide={() => setAddShowModal(false)} />
                </>
            }
        </>
    );
}