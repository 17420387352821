import { Badge, Button, ButtonGroup, Card, Col, Container, FormGroup, FormLabel, Image, ProgressBar, Row } from "react-bootstrap";
import MyBreadCrumb from "../includes/MyBreadCrumb";
import { detailBreadcrumb, likeAlso, tourDetailGallery } from "../../appData";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import { AiFillStar, AiOutlineHeart, AiOutlineShareAlt, AiOutlineStar } from "react-icons/ai";
import { useState } from "react";
import ReactImageGallery from "react-image-gallery";
import HeadingUnderline from "../includes/HeadingUnderline";
import SearchResultSingleItem from "../includes/SearchResultSingleItem";
import ShowOnMap from "../includes/ShowOnMap";
import DateRangePicker from "react-bootstrap-daterangepicker";

const TourDetail = () => {
    const [rating, setRating] = useState(4);
    return (
        <>
            <MyBreadCrumb items={detailBreadcrumb} />
            <Container className="detail--page-container">
                <Row className="mb-2">
                    <Col xs={12} md={9}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <div>
                                <div className="d-flex">
                                    <h5>New Road Hote</h5>
                                    &nbsp;&nbsp;&nbsp;
                                    <Rating
                                        initialRating={rating}
                                        emptySymbol={<AiOutlineStar />}
                                        fullSymbol={<AiFillStar style={{ color: "#B0D12B" }} />}
                                        onChange={(newRating) => setRating(newRating)} />
                                </div>
                                <div className="d-flex align-items-center" style={{ paddingLeft: 20 }}>
                                    <span>New York, United States</span>
                                    <Link to={"#"} className="custom-text-color ms-2">View on map</Link>
                                </div>
                            </div>
                        </div>

                        <Row className="mt-3">
                            <Col xs={12}>
                                <ReactImageGallery items={tourDetailGallery} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>Description</h5>
                                <p className="text-muted">The 4-star Park Central Hotel offers comfort and convenience whether you’re on business or holiday in New York (NY). Featuring
                                    a complete list of amenities, guests will find their stay at the property a comfortable one. Service-minded staff will welcome and
                                    guide you at the Park Central Hotel. Air conditioning, heating, desk, alarm clock, iPod docking station can be found in selected
                                    guestrooms. The hotel offers various recreational opportunities. Park Central Hotel combines warm hospitality with a lovely
                                    ambiance to make your stay in New York (NY) unforgettable.</p>
                                <Link to={"#"} className="custom-text-color">View More</Link>
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col xs={12}>
                                <h5>Select your room</h5>
                                <Card className="mb-3">
                                    <Row className="p-2">
                                        <Col xs={12} md={3}>
                                            <Card.Img src="./../images/rooms/room-1.png" width={150} height={130} />
                                        </Col>
                                        <Col xs={12} md={9}>
                                            <Card.Body>
                                                <h6>Deluxe Twin Room</h6>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">15m<sup>2</sup></span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">2 single beds</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Free Wifi</span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Shower and bathtab</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Link to={"#"} className="custom-text-color">Room Photos and Details</Link>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Col>
                                        <div className="bg-warning ribbon">Today's best offer</div>
                                    </Row>
                                </Card>

                                <Card className="mb-3">
                                    <Row className="p-2">
                                        <Col xs={12} md={3}>
                                            <Card.Img src="./../images/rooms/room-2.png" width={150} height={130} />
                                        </Col>
                                        <Col xs={12} md={9}>
                                            <Card.Body>
                                                <h6>Deluxe Twin Room</h6>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">15m<sup>2</sup></span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">2 single beds</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Free Wifi</span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Shower and bathtab</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Link to={"#"} className="custom-text-color">Room Photos and Details</Link>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Col>
                                        <div className="bg-danger ribbon">Save 13% Today</div>
                                    </Row>
                                </Card>

                                <Card className="mb-3">
                                    <Row className="p-2">
                                        <Col xs={12} md={3}>
                                            <Card.Img src="./../images/rooms/room-3.png" width={150} height={130} />
                                        </Col>
                                        <Col xs={12} md={9}>
                                            <Card.Body>
                                                <h6>Deluxe Twin Room</h6>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">15m<sup>2</sup></span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">2 single beds</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Free Wifi</span></Col>
                                                    <Col xs={6} md={6}><span className="text-muted font-12">Shower and bathtab</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Link to={"#"} className="custom-text-color">Room Photos and Details</Link>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Col>
                                        <div className="bg-danger ribbon hide">Save 13% Today</div>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>Amenities</h5>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/air-conditioner.png" />
                                        <label>Air Conditioning</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/bathrobe.png" />
                                        <label>Bathrobes</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/folded-towel.png" />
                                        <label>Dry Cleaning</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/weightlifting.png" />
                                        <label>Fitness center</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/hair-dryer.png" />
                                        <label>Hair Dryer</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/desk-chair.png" />
                                        <label>High Chair</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/wine-glass.png" />
                                        <label>Mini Bar</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/hamburger.png" />
                                        <label>Restaurant</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/slippers.png" />
                                        <label>Slippers</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/phone-call.png" />
                                        <label>Telephone</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/alarm.png" />
                                        <label>Pickup</label>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Image src="./../images/icons/wifi.png" />
                                        <label>Wifi</label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col xs={12}>
                                <h5>Nearest Essentials</h5>
                                <Row className="mb-3">
                                    <Col xs={12} md={4}>
                                        <div className="d-flex flex-column">
                                            <label>Airports</label>
                                            <span className="text-muted">London City Airport (LCY)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                            <span>Heathrow Airport (LHR)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="d-flex flex-column">
                                            <label>Airports</label>
                                            <span className="text-muted">London City Airport (LCY)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                            <span>Heathrow Airport (LHR)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="d-flex flex-column">
                                            <label>Airports</label>
                                            <span className="text-muted">London City Airport (LCY)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                            <span>Heathrow Airport (LHR)</span>
                                            <Link to={"#"} className="custom-text-color">14.4 km</Link>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row className="mb-3">
                                    <Col xs={12} md={4}>
                                        <label>Horsepower</label>
                                        <br/><span>200</span>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <label>Transmission</label>
                                        <br/><span>Manual</span>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <label>Condition</label>
                                        <br/><span>New</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={4}>
                                        <label>Drive</label>
                                        <br/><span>Rear</span>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <label>Warranty</label>
                                        <br/><span>Yes</span>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <label>Hospital or clinic</label>
                                        <br/><span>The willington Hopital 2.1 km</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col xs={12}>
                                <h5>What's Nearby</h5>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <label>Popular Landmarks</label>
                                        <ul>
                                            <li>landmark - 1</li>
                                            <li>landmark - 2</li>
                                            <li>landmark - 3</li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label>Nearby Landmarks</label>
                                        <ul>
                                            <li>landmark - 1</li>
                                            <li>landmark - 2</li>
                                            <li>landmark - 3</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col xs={12}>
                                <h5>Guest Policy</h5>
                                <label>Check-in/Check-out</label>
                                <ul>
                                    <li>Check-in from: 15:00</li>
                                    <li>Check-out until: 11:00</li>
                                    <li>Reception open until: 12:00</li>
                                </ul>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col xs={12}>
                                <h5>Children</h5>
                                <p>An extra bed will be provided to accommodate any child included in the booking for a charge mentioned below. INR 2000 will be
                                    charged for an extra cot per child. (To be paid at the property). Cribs are available for children below 3 years.</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>Extra Beds</h5>
                                <ul>
                                    <li>An extra bed will be provided to accommodate any additional guest included in the booking for a charge mentioned below.</li>
                                    <li>Extra beds along with blankets and pillows be provided.</li>
                                </ul>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>Others</h5>
                                <ul>
                                    <li>A luxurious body massage at the spa and a dip in the swimming pool will help you relax</li>
                                    <li>20% Discount on Spa</li>
                                    <li>Free Parking.</li>
                                    <li>Hand sanitizer is provided in guest accommodation and common areas.</li>
                                </ul>
                            </Col>
                        </Row>

                        <hr />
                        <h5>Average Reviews</h5>
                        <Row>
                            <Col xs={12} md={4}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex flex-direction-column'>
                                            <div>
                                                <span className="average-review-count">5.0/</span>
                                                <sub className="average-review-count-total">5</sub>
                                            </div>
                                            <span className="average-review-title">Exceptional</span>
                                            <span className='text-muted'>From 1 Review</span>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={8}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Cleanliness</FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={40} label={`${40}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">4.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Facilities</FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={60} label={`${60}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">6.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Location</FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={60} label={`${60}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">6.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Service</FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={60} label={`${60}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">6.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Value for money</FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={60} label={`${60}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">6.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <FormLabel className='text-muted fontWeight-400 mb-1'>Room comfort and quality </FormLabel>
                                            <Row>
                                                <Col xs={10} md={10} style={{display: "grid", alignItems: "center"}}><ProgressBar now={60} label={`${60}%`} variant="custom-variant" /></Col>
                                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">6.0</span></Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />
                        <h5>Showing 1 review</h5>
                        <Row>
                            <Col xs={2} md={2}>
                                <Image src="./../images/girl-1.jpg" roundedCircle width={50} height={50} />
                            </Col>
                            <Col xs={10} md={10}>
                                <label>Ashleigh</label>
                                <span className="text-muted">February 14, 2022</span>
                                <div>
                                    <Badge bg="custom-variant" style={{ width: "auto" }}>5.0/5</Badge>
                                    <label>The best hotel ever "</label>
                                </div>
                                <p>
                                    Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere ultricies tortor imperdiet vitae.
                                    Curabitur lacinia neque non metus
                                </p>
                            </Col>
                        </Row>

                        <hr />
                        <h5>Add a review</h5>
                        <p>You must be <Link to="/login" className='custom-text-color'>logged in</Link> to post a review.</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className="mb-2">
                            <Col xs={12} md={12} className="d-flex">
                                <div className="me-2">
                                    <Button variant="default" className="me-2 favorite--btn"><AiOutlineHeart /></Button>
                                    <Button variant="default" className="me-2 share--btn"><AiOutlineShareAlt /></Button>
                                    <Button variant="default" className="rating--btn">4.0/5</Button>
                                </div>
                                <div className="d-flex flex-direction-column">
                                    <span>Excellent</span>
                                    <span className="font-12 text-muted">(1 Review)</span>
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <ButtonGroup aria-label="Basic example" style={{width: "100%"}}>
                                    <Button variant="outline-dark" className="custom--tab-lowest-price my--active-tab">Lowest price include</Button>
                                    <Button variant="outline-dark" className="custom--tab-free-breakfast">Free breakfast</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header style={{ backgroundColor: "transparent" }}>
                                <div className="d-flex"><span className="text-muted">From</span> &nbsp;<h5 className="mb-0">$711.00</h5></div>
                            </Card.Header>
                            <Card.Body>

                                <FormGroup className="mb-3">
                                    <FormLabel className='text-muted mb-0'>Check In - Out</FormLabel>
                                    <DateRangePicker
                                        initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                                    >
                                        <button className='form-control material-input' style={{ fontWeight: 500, paddingLeft: 0 }}>
                                            Aug 26/2023 - Aug 27/2023
                                        </button>
                                    </DateRangePicker>
                                </FormGroup>

                                <FormGroup className="mb-3">
                                    <FormLabel className="text-muted">Guests</FormLabel>
                                    <select className="form-control material-input" style={{ fontWeight: 500 }}>
                                        <option>1 Room - 4 Guests</option>
                                        <option>1 Room - 4 Guests</option>
                                        <option>1 Room - 4 Guests</option>
                                    </select>
                                </FormGroup>

                                <div className="d-grid gap-2">
                                    <Button variant="primary" className="btn-custom-color" size="lg">
                                        Update
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mt-3">
                            <Card.Body>
                                <ShowOnMap />
                                <div className="d-flex align-items-center justify-content-center">
                                    <Button variant="outline-danger">3.7</Button>
                                    <div className="d-flex flex-direction-column" style={{ paddingLeft: 5 }}>
                                        <span>Very Good</span>
                                        <span className="text-muted">Location rating score</span>
                                    </div>
                                </div>
                                <ul>
                                    <li><label>Customer care available 24/7</label></li>
                                    <li><label>Free Travel Insureance</label></li>
                                    <li><label>Hand-picked Tours & Activities</label></li>
                                    <li><label>No-hassle best price guarantee</label></li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <h5 className="text-center">Similar Hotels</h5>
                        <HeadingUnderline />
                        <Row>
                            {likeAlso && likeAlso.length > 0 && likeAlso.map((item, key) => (
                                <Col xs={6} md={3} key={key} className="mb-3">
                                    <Link to={"#"} className="text-decoration-none">
                                        <SearchResultSingleItem imgUrl={item.image_url} requestFrom='similar' item={item} />
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TourDetail;