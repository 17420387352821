import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function AboutPageCustomerReview({ reviews }) {
    console.log("reviews: ", reviews)
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };
    return (
        <>
            <Carousel indicators={false} prevIcon={null} nextIcon={null} activeIndex={activeIndex} onSelect={handleSelect}>
                {reviews && reviews.length > 0 && reviews.map((customer, key) => (
                    <Carousel.Item className="text-center" key={key}>
                        <div>
                            <img
                                className="rounded-circle mb-3"
                                style={{ width: 150, height: 150 }}
                                src={customer?.userId?.avatar}
                                alt={customer?.userId?.avatar}
                            />
                        </div>

                        <div className='d-flex justify-content-center'><p style={{ maxWidth: 530, textAlign: 'center', color: "#67747C" }}><i>{customer?.comment}</i></p></div>
                        <h5 className='mb-0' style={{ color: "#000", fontWeight: 600 }}>{customer?.userId?.name}</h5>
                        <Link to={"#"} className='custom-text-color'>{customer?.userId?.role}</Link>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="d-flex justify-content-center align-items-center mt-3 custom-indicator-circle">
                {reviews.map((_, index) => (
                    <span
                        key={index}
                        className={`carousel-indicator ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => { handleSelect(index) }}
                    />
                ))}
            </div>
        </>
    );
}

export default AboutPageCustomerReview;