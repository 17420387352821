import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { aboutPageMainSlider } from '../../appData';

const AboutUsPageSlider = ({slider}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };
    return (
        <div>
        <Carousel indicators={false} prevIcon={null} nextIcon={null} activeIndex={activeIndex} onSelect={handleSelect}>
            {slider && slider.length > 0 && slider.map((item, key) => (
                <Carousel.Item key={key}>
                    <img
                        className="d-block w-100"
                        src={item}
                        alt={item}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
        <div className="d-flex justify-content-center align-items-center mt-3 custom-indicator-circle">
                {slider.map((_, index) => (
                    <span
                        key={index}
                        className={`carousel-indicator ${index === activeIndex ? 'active' : '' }`}
                        onClick={() => { handleSelect(index) }}
                    />
                ))}
            </div>
        </div>
    );
}

export default AboutUsPageSlider;