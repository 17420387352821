import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

const Loading = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <BootstrapSpinner animation="border" variant="danger" />
        </div>
    );
};

export default Loading;