import { Button, Col, Form, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { API_REQUEST_HEADER, REGISTER_ENDPOINT } from "../../endpoints";

export default function Signup() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false); // State for form validation

    const handleNextClick = () => {
        const form = document.getElementById('registerForm');
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        // Your axios POST request goes here
        const data = {
            name: firstName + " " + lastName,
            email: email,
            password: password
        }
        axios.post(REGISTER_ENDPOINT, data, API_REQUEST_HEADER)
            .then(response => {
                navigate("/login");
            })
            .catch(err => {
                setError(err?.response?.data?.message)
            });
    }

    return (
        <>
            <div className="singup-inner-wrapper">
                {/* <Alert variant="danger">some text</Alert> */}
                <h3>Sign Up</h3>
                <p>Please fill your information below</p>
                <Form id="registerForm" noValidate validated={validated}>
                    <FormGroup>
                        <FormLabel>First name</FormLabel>
                        <FormControl
                            type="text"
                            className="custom-input-style"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            placeholder="Enter first name"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter your first name.
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Last name</FormLabel>
                        <FormControl
                            type="text"
                            className="custom-input-style"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            placeholder="Enter last name"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter your last name.
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <FormControl
                            type="email"
                            className="custom-input-style"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter email address"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email address.
                        </Form.Control.Feedback>
                    </FormGroup>

                    <FormGroup className={"mb-2"}>
                        <FormLabel>Password</FormLabel>
                        <FormControl
                            type="password"
                            className="custom-input-style"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter your password"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a password.
                        </Form.Control.Feedback>
                    </FormGroup>

                    {error && error !== null &&
                        <Row>
                            <Col auto>
                                <div className="alert alert-danger">{error}</div>
                            </Col>
                        </Row>
                    }

                    <div className="d-flex justify-content-end align-items-center mt-3 mb-3">
                        <Button
                            variant="primary"
                            as={Link}
                            className="btn-next"
                            onClick={() => handleNextClick()}
                            type="button" // Set as type="button to prevent form submission
                        >
                            Next &nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </div>
                </Form>

                <div className="d-flex justify-content-space-between">
                    <span>Already have an account</span>
                    <Link to={"/login"} style={{ textDecoration: "none", color: "#F14778" }}>
                        Login to your account
                    </Link>
                </div>
            </div>
        </>
    );
}