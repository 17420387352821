import { Container } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function MyBreadCrumb({ items }) {
    console.log(items);
    return (
        <div style={{ width: "100%" }} className='custom-menu-bar'>
            <Container>
                <Breadcrumb separator=">">
                    {items && items.length > 0 && items.map(item => (
                         <Breadcrumb.Item key={item.url} href={item.url} className={item.isActive ? 'active' : ''}>{item.title}</Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            </Container>
        </div>
    );
}

export default MyBreadCrumb;