import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../endpoints';
import { Button, Col, Dropdown, Form, FormControl, FormGroup, FormLabel, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLocation, faUsers } from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { formatDateString } from '../../../helpers/common-helper';
import axios from 'axios';
import { connect } from 'react-redux';

const Add = ({ setSearchResults, show, onHide, setSearchFormModal }) => {
    const navigate = useNavigate();
    const [isDateRangePickerEvent, setIsDateRangePickerEvent] = useState(false);
    const [key, setKey] = useState('hotel');
    const [validated, setValidated] = useState(false);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(18);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [formModal, setFormModal] = useState({
        destination: '',
        dateRange: {
            start: 'Aug 26/2024',
            end: 'Aug 27/2024',
            formatedStart: new Date('YYYY-MM-DD'),
            formatedEnd: new Date('YYYY-MM-DD')
        },
        rooms: 1,
        adult: 1,
        children: 0,
        infant: 0,
        limit: pageLimit,
        page: selectedPageNumber,
        sortBy: 'ASC'
    });

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateRangePickerEvent) {
            return;
        }

        if (!formModal.destination) {
            setValidated(true);
            return;
        }

        axios.post(`${API_BASE_URL}landing/search`, formModal, {
            headers: {
                'accept': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                setSearchResults(response.data);
                setSearchFormModal(formModal);
                navigate('/dashboard/search-results');
            }
            console.log(response);
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleDateRangeApply = (event, picker) => {
        setIsDateRangePickerEvent(false);
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        setFormModal({
            ...formModal,
            dateRange: {
                start: formatDateString(startDate),
                end: formatDateString(endDate),
                formatedStart: startDate,
                formatedEnd: endDate
            },
        });
    }

    useEffect(() => {
        if (isDateRangePickerEvent) {
            document.body.style.overflowY = 'scroll';
            return () => {
                document.body.style.overflowY = 'auto';
            };
        }
    }, [isDateRangePickerEvent]);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const hideDropdown = () => {
        setDropdownOpen(false);
    };

    const tabContent = <div style={{ backgroundColor: 'white', padding: '15px' }}>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Row className='bg-light box-white'>
                <Col xs={12} md={3} className='mb-2'>
                    <FormGroup>
                        <FormLabel className='text-muted font-12'>Destination or Hotel Name</FormLabel>
                        <div style={{
                            display: "flex",
                            paddingRight: "0px",
                            alignItems: "center"
                        }}>
                            <div style={{ position: "relative", left: 0, top: 0, background: 'white', height: '37px', display: 'flex', borderBottom: '1px solid #dee2e6', alignItems: 'center', paddingLeft: 10 }}><FontAwesomeIcon icon={faLocation} color='#F14778' /></div>
                            <FormControl
                                type="text"
                                placeholder='Where are you going?'
                                className='material-input'
                                required
                                value={formModal.destination}
                                onChange={(e) => setFormModal({ ...formModal, destination: e.target.value })}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <FormLabel className='text-muted font-12'>Check In - Out</FormLabel>
                        <DateRangePicker
                            initialSettings={{ startDate: new Date(), endDate: '1/1/2050' }}
                            onApply={handleDateRangeApply}
                            onEvent={(event, picker) => {
                                if (event.type === 'show' || event.type === 'showCalendar ') {
                                    setIsDateRangePickerEvent(true);
                                } else if (event.type === 'hide') {
                                    setIsDateRangePickerEvent(false);
                                }
                            }}
                        >
                            <button className='form-control material-input' style={{ textAlign: 'left' }}>
                                <FontAwesomeIcon icon={faCalendar} color='#F14778' />
                                <span style={{ marginLeft: 15 }} className='text-muted'>
                                    {`${formModal.dateRange.start}-${formModal.dateRange.end}`}
                                </span>
                            </button>
                        </DateRangePicker>
                    </FormGroup>
                </Col>
                <Col xs={12} md={3} className='mb-3'>
                    <FormGroup>
                        <FormLabel className='text-muted font-12'>Rooms and Guests</FormLabel>
                        <div className="dropdown">
                            <div style={{
                                display: "flex",
                                paddingRight: "0px",
                                alignItems: "center"
                            }}>
                                <div style={{ position: "relative", left: 0, top: 0, background: 'white', height: '37px', display: 'flex', borderBottom: '1px solid #dee2e6', alignItems: 'center', paddingLeft: 10 }}><FontAwesomeIcon icon={faUsers} color='#F14778' /></div>
                                <input
                                    type="text"
                                    placeholder="Adults, Children, Infant"
                                    value={`Adult ${formModal.adult}, Childs ${formModal.children}, Infant ${formModal.infant}`}
                                    onClick={toggleDropdown}
                                    className='form-control material-input'
                                />
                            </div>
                            {isDropdownOpen && (
                                <div className="dropdown-content" style={{ position: 'absolute', background: 'white', width: '100%', padding: 15 }}>
                                    <input type="number" placeholder="Adult" className='form-control mb-2' value={formModal.adult} onChange={(e) => setFormModal({ ...formModal, adult: e.target.value })} />
                                    <input type="number" placeholder="Children" className='form-control mb-2' value={formModal.children} onChange={(e) => setFormModal({ ...formModal, children: e.target.value })} />
                                    <input type="number" placeholder="Infant" className='form-control mb-2' value={formModal.infant} onChange={(e) => setFormModal({ ...formModal, infant: e.target.value })} />
                                    <button className="custom-done-btn" onClick={hideDropdown}>
                                        Done
                                    </button>
                                </div>
                            )}
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2} className='d-flex align-items-center justify-content-center'>
                    <div className="d-grid gap-2" style={{ width: '100%' }}>
                        <Button type="submit" variant="danger" size="lg" style={{ backgroundColor: "#F14778" }}>
                            Search
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    </div>;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Booking
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="hotel" title={
                            <span>
                                <Image src="./../images/icons/hotel.png" width={21} height={25} />
                                {" Hotel"}
                            </span>}
                        >
                            {tabContent}
                        </Tab>
                        <Tab eventKey="tours" title={
                            <span>
                                <Image src="./../images/icons/tours.png" width={21} height={21} />
                                {" Tours"}
                            </span>
                        }
                        >
                            {tabContent}
                        </Tab>
                        <Tab eventKey="activity" title={
                            <span>
                                <Image src="./../images/icons/activity.png" width={21} height={21} />
                                {" Activity"}
                            </span>
                        }>
                            {tabContent}
                        </Tab>
                        <Tab eventKey="rental" title={
                            <span>
                                <Image src="./../images/icons/rental.png" width={21} height={21} />
                                {" Rental"}
                            </span>}>
                            {tabContent}
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({

});
const dispatchStateToProps = (dispatch) => ({
    setSearchResults: (values) => {
        dispatch({
            type: "UPDATE_DASHBOARD_BOOKING_SEARCH_RESULTS",
            payload: values
        })
    },
    setSearchFormModal: (formData) => {
        dispatch({
            type: "UPDATE_BOOKING_SEARCH_FORM_MODAL",
            payload: formData
        })
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(Add);