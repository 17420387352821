import { Outlet } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

export default function AuthLayout(props) {
	return (
		<>
			<Container fluid className="vh-100" style={{ paddingLeft: 0 }}>
				<Row className={"h-100"}>
					<Col md={5} lg={5} xs={12} className="signup-background">
						<Button variant="light" className="rounded-circle me-2">
							<FaFacebookF />
						</Button>
						<Button variant="light" className="rounded-circle me-2">
							<FaInstagram />
						</Button>
						<Button variant="light" className="rounded-circle">
							<FaTwitter />
						</Button>
					</Col>
					<Col md={7} lg={7} xs={12}>
						<Outlet />
					</Col>
				</Row>
			</Container>
		</>
	);
}