import React from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";

const MyPagination = ({ page, totalPages, list, onPageChange, pageLimit, totalResults }) => {
  // Create an array of page numbers based on totalPages
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    list && list.length > 0 ? (
      <Row className='mb-3'>
        <Col xs={12} md={4}>
          <div className='d-flex align-items-center bold'><p>Showing {totalResults < pageLimit ? totalResults : pageLimit} from {totalResults} data</p></div>
        </Col>
        <Col xs={12} md={8}>
          <div className='d-flex justify-content-end'>
            <Pagination>
              <Pagination.Prev
                className="pagination-previous-btn"
                disabled={page === 1}
                onClick={() => onPageChange(page - 1)}
              >
                <MdOutlineKeyboardDoubleArrowLeft /> Previous
              </Pagination.Prev>

              {pages.map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === page}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}

              <Pagination.Next
                className="pagination-next-btn"
                disabled={page === totalPages}
                onClick={() => onPageChange(page + 1)}
              >
                Next <MdOutlineKeyboardDoubleArrowRight />
              </Pagination.Next>
            </Pagination>
          </div>
        </Col>
      </Row>
    ) : null
  );
};

export default MyPagination;
