import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Image, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import { FaInfoCircle } from 'react-icons/fa';
import { API_BASE_URL, CREATE_HOTEL_ENDPOINT } from '../../../endpoints';
import { doUpload } from '../../../helpers/uploads';
import ReactDatePicker from 'react-datepicker';
import TimePicker from 'react-bootstrap-time-picker';
import { DEFAULT_CHILDREN_POLICY, DEFAULT_EXTRA_BEDS_POLICY, DEFAULT_GUEST_POLICY, DEFAULT_OTHERS_POLICY } from '../../../config/constant';

export default function Add(props) {
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const [isUploading, setIsUploading] = useState(false);
    const [apiResponse, setApiResponse] = useState({});
    const [validated, setValidated] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [formModal, setFormModal] = useState({
        userId: JSON.parse(localStorage.getItem("user"))?.id,
        thumbnails: [],
        name: '',
        email: '',
        roomType: 'single',
        numberOfGuests: '',
        arrivalDateTime: {
            date: '',
            time: 32400
        },
        departureDate: {
            date: '',
            time: 32400
        },
        pickup: 'yes',
        status: 'pending',
        specialRequest: '',
        guestPolicy: DEFAULT_GUEST_POLICY,
        childrenPolicy: DEFAULT_CHILDREN_POLICY,
        extraBedsPolicy: DEFAULT_EXTRA_BEDS_POLICY,
        others: DEFAULT_OTHERS_POLICY,
        city: '',
        destinationId: null,
        description: null
    });

    useEffect(() => {
        getDestinations();
    }, []);

    useEffect(() => {
        if (!props.show) {
            setApiResponse({});
            console.log("api response state set to  {}");
        }
    }, [props.show]);

    const onDrop = useCallback(async (selectedFiles) => {
        const uploadFile = async (file) => {
            try {
                const result = await doUpload(file);
                const updatedFormModal = { ...formModal };
                updatedFormModal.thumbnails.push(result);
                setFormModal(updatedFormModal);
            } catch (error) {
                console.error(error);
            }
        };

        for (const file of selectedFiles) {
            await uploadFile(file);
        }
    }, [formModal]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
    });

    const onSubmitHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        if (form.checkValidity() === false ||
            !formModal.numberOfGuests ||
            !formModal.arrivalDateTime.date ||
            !formModal.arrivalDateTime.time ||
            !formModal.departureDate.date ||
            !formModal.departureDate.time ||
            formModal.thumbnails.length == 0) {
            setValidated(true);
            return;
        }

        axios.post(CREATE_HOTEL_ENDPOINT, formModal, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 201) {
                setApiResponse({ type: "success", message: "New hotel created successfully!" });
                setFormModal({
                    userId: JSON.parse(localStorage.getItem("user"))?.id,
                    thumbnails: [],
                    name: '',
                    email: '',
                    roomType: 'single',
                    numberOfGuests: '',
                    arrivalDateTime: {
                        date: '',
                        time: 32400
                    },
                    departureDate: {
                        date: '',
                        time: 32400
                    },
                    pickup: 'yes',
                    status: 'pending',
                    specialRequest: '',
                    guestPolicy: DEFAULT_GUEST_POLICY,
                    childrenPolicy: DEFAULT_CHILDREN_POLICY,
                    extraBedsPolicy: DEFAULT_EXTRA_BEDS_POLICY,
                    others: DEFAULT_OTHERS_POLICY,
                    city: '',
                    destinationId: null,
                    description: null
                });
            }
        }).catch(err => {
            setApiResponse({ type: "danger", message: err?.response?.data?.message });
            console.log(err);
        });
    }

    const getDestinations = () => {
        axios.get(`${API_BASE_URL}destinations`, {
            headers: {
                'Content-Type': "application/json"
            }
        }).then(res => {
            if (res.status === 200) {
                setDestinations(res.data.destinations);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Hotel
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                <Form noValidate validated={validated} onSubmit={onSubmitHandle}>
                    <Row className='p-3'>
                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Select Photo <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <div {...getRootProps({ className: `dropzone ${validated && formModal.thumbnails.length === 0 ? 'invalid' : ''}` })}>
                                    <input {...getInputProps()} />
                                    {formModal.thumbnails && formModal.thumbnails.length === 0 ?
                                        <Row>
                                            <Col xs={12} md={4} style={{ textAlign: "left" }}>
                                                <Image src="./../images/no-image.png" width={119} height={120} />
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <span className='text-muted'>
                                                    Drag & Drop <br /> <span>OR</span> <br /> <span className='text-danger'>Browse Photo</span> <br /><br /> Supports: *.png, *.jpg and *.jpeg
                                                </span>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className='d-flex' style={{ overflow: 'auto' }}>
                                                {formModal.thumbnails.map((thumbnail, key) => (
                                                    <div key={key}>
                                                        {/* <button style={{position: 'absolute', right:0}}><FaTimes /></button> */}
                                                        <Image src={thumbnail} width={119} height={120} style={{ border: '1px solid #e5e5e5', borderRadius: 15, marginRight: 10 }} />
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Name</FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Full Name'
                                    value={formModal.name}
                                    onChange={(e) => setFormModal({ ...formModal, name: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Email <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="email"
                                    placeholder='jhon@example.com'
                                    required
                                    value={formModal.email}
                                    onChange={(e) => setFormModal({ ...formModal, email: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6}>
                            <FormGroup>
                                <FormLabel>Destination <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormSelect
                                    value={formModal.destinationId}
                                    onChange={(e) => setFormModal({ ...formModal, destinationId: e.target.value })}
                                >
                                    {destinations && destinations.length > 0 && destinations.map((dest, key) => (
                                        <option key={key} value={dest.id}>{dest.name}</option>
                                    ))}
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6}>
                            <FormGroup>
                                <FormLabel>City <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="test"
                                    placeholder='Lahore'
                                    required
                                    value={formModal.city}
                                    onChange={(e) => setFormModal({ ...formModal, city: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Room Type</FormLabel>
                                <FormSelect placeholder='Select Room Type' value={formModal.roomType} onChange={(e) => setFormModal({ ...formModal, roomType: e.target.value })}>
                                    <option value='single'>Single</option>
                                    <option value='double'>Double</option>
                                    <option value='triple'>Triple</option>
                                    <option value='others'>other</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>No#. of guests <span className='required'>{" * "}</span></FormLabel>
                                <FormControl
                                    type="number"
                                    placeholder='Select No#. of guests'
                                    required
                                    value={formModal.numberOfGuests}
                                    onChange={(e) => setFormModal({ ...formModal, numberOfGuests: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup hasValidation>
                                <FormLabel>Arrival Date & Time <span className='required'>{" * "}</span></FormLabel>
                                <Row>
                                    <Col xs={12} md={8}>
                                        <ReactDatePicker
                                            selected={formModal.arrivalDateTime.date}
                                            onChange={(date) => setFormModal({ ...formModal, arrivalDateTime: { ...formModal.arrivalDateTime, date: date } })}
                                            dateFormat="MMM d / yyyy"
                                            className={`form-control ${validated && !formModal.arrivalDateTime.date && 'invalid'}`}
                                            placeholderText='Aug 26 / 2024'
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <TimePicker
                                            start="09:00"
                                            end="18:00"
                                            step={30}
                                            value={formModal.arrivalDateTime.time}
                                            onChange={(time) => setFormModal({
                                                ...formModal,
                                                arrivalDateTime: { ...formModal.arrivalDateTime, time },
                                            })}
                                            format={12}
                                            className={`form-control ${validated && !formModal.arrivalDateTime.time && 'invalid'}`}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup hasValidation>
                                <FormLabel>Departure Date <span className='required'>{" * "}</span></FormLabel>
                                <Row>
                                    <Col xs={12} md={8}>
                                        <ReactDatePicker
                                            selected={formModal.departureDate.date}
                                            onChange={(date) => setFormModal({ ...formModal, departureDate: { ...formModal.departureDate, date: date } })}
                                            dateFormat="MMM d / yyyy"
                                            className={`form-control ${validated && !formModal.departureDate.date && 'invalid'}`}
                                            placeholderText='Aug 30 / 2024'
                                        />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <TimePicker
                                            start="09:00"
                                            end="18:00"
                                            step={30}
                                            value={formModal.departureDate.time}
                                            onChange={(time) => setFormModal({
                                                ...formModal,
                                                departureDate: { ...formModal.departureDate, time },
                                            })}
                                            format={12}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <FormGroup>
                                <FormLabel>Pickup</FormLabel>
                                <FormSelect placeholder='Pickup' value={formModal.pickup} onChange={(e) => setFormModal({ ...formModal, pickup: e.target.value })}>
                                    <option value='yes'>Yes</option>
                                    <option value='no'>No</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <FormGroup>
                                <FormLabel>Status</FormLabel>
                                <FormSelect placeholder='Status' value={formModal.status} onChange={(e) => setFormModal({ ...formModal, status: e.target.value })}>
                                    <option value="pending">Pending</option>
                                    <option value="canceled">Canceled</option>
                                    <option value="booked">Booked</option>
                                    <option value="refund">Refund</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12} className='mt-2'>
                            <FormGroup>
                                <FormLabel>Special Request</FormLabel>
                                <FormControl
                                    value={formModal.specialRequest}
                                    onChange={(e) => setFormModal({ ...formModal, specialRequest: e.target.value })}
                                    as="textarea"
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12} className='mt-2'>
                            <FormGroup>
                                <FormLabel>Description</FormLabel>
                                <FormControl
                                    value={formModal.description}
                                    onChange={(e) => setFormModal({ ...formModal, description: e.target.value })}
                                    as="textarea"
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className='d-flex' style={{ justifyContent: 'center' }}>
                        <Button variant="outline-light" className='text-dark' onClick={props.onHide}>Discard</Button>
                        <Button variant='danger' className='bg-custom-variant' type="submit">Add Hotel</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}