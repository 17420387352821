import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Form, FormCheck, FormControl, FormGroup, FormLabel, FormSelect, Image, InputGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import 'holderjs';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import { API_BASE_URL, API_REQUEST_HEADER, GET_HOTELS_ENDPOINT } from '../../../endpoints';
import { doUpload } from '../../../helpers/uploads';
import { Link } from 'react-router-dom';
import { cutomDateTimeFormat, getRoomInitalState } from '../../../helpers/common-helper';
import ReactSelect from 'react-select';
import { cloneDeep } from 'lodash';

export default function Add(props) {
    const [validated, setValidated] = useState(false);
    const [searchFacilities, setSearchFacilities] = useState([]);
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [hotels, setHotels] = useState([]);
    const [apiResponse, setApiResponse] = useState({});
    const [isDateRangePickerEvent, setIsDateRangePickerEvent] = useState(false);
    const [formModal, setFormModal] = useState(getRoomInitalState);
    const [uploading, setUploading] = useState(false);

    const getHotels = () => {
        axios.get(GET_HOTELS_ENDPOINT(userId, 1000, 1), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setHotels(response?.data?.hotels);
                if (response.data && response.data.hotels && response.data.hotels.length > 0) {
                    setFormModal({ ...formModal, hotelId: response?.data.hotels[0]?.id })
                }
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const onDrop = useCallback(async (selectedFiles) => {
        const uploadFile = async (file) => {
            try {
                const result = await doUpload(file);
                if (props && props.editModal && Object.keys(props.editModal).length > 0) {
                    const updatedEditModal = { ...props.editModal };
                    updatedEditModal.thumbnails.push(result);
                    props.setEditModal(updatedEditModal);
                } else {
                    const updatedFormModal = { ...formModal };
                    updatedFormModal.thumbnails.push(result);
                    setFormModal(updatedFormModal);
                }
                setUploading(false);
            } catch (error) {
                console.error(error);
            }
        };

        for (const file of selectedFiles) {
            setUploading(true);
            await uploadFile(file);
        }
    }, [formModal]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // You can restrict file types (e.g., accept: 'image/*,application/pdf')
    });

    useEffect(() => {
        getHotels();
    }, []);

    const handleAddNewSeason = () => {
        const isEditModal = props && props.editModal && Object.keys(props.editModal).length > 0;
        const targetModal = isEditModal ? props.editModal : formModal;

        const newSeason = {
            "seasonName": "Season " + (targetModal?.prices.length + 1),
            "dateRange": {
                "from": new Date(),
                "to": new Date()
            },
            "price": 2000,
            "currencySymbol": "$"
        };

        const updatedModal = { ...targetModal };
        updatedModal.prices.push(newSeason);

        isEditModal ? props.setEditModal(updatedModal) : setFormModal(updatedModal);
    };


    const handleRemoveSeason = (keyToRemove) => {
        if (props && props.editModal && Object.keys(props.editModal).length > 0) {
            if (props.editModal.prices.length === 1) {
                return;
            }
            const updatedPrices = props.editModal.prices.filter((price, key) => key !== keyToRemove);
            props.setEditModal({
                ...props.editModal,
                prices: updatedPrices,
            });
        } else {
            if (formModal.prices.length === 1) {
                return;
            }
            const updatedPrices = formModal.prices.filter((price, key) => key !== keyToRemove);
            setFormModal({
                ...formModal,
                prices: updatedPrices,
            });
        }
    }

    const handleInputChange = (inputValue) => {
        axios.get(`${API_BASE_URL}facilities/search?inputValue=${inputValue}`).then(res => {
            if (res.status === 200) {
                const newOptions = res?.data.map((item) => ({
                    value: item.id,
                    label: item.title,
                }));
                setSearchFacilities(newOptions);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    const handlePriceChange = (index, newPrice) => {
        if (props && props.editModal && Object.keys(props.editModal).length > 0) {
            const updatedPrices = props.editModal.prices.map((price, i) =>
                i === index ? { ...price, price: newPrice } : price
            );
            props.setEditModal({ ...props.editModal, prices: updatedPrices });
        } else {
            const updatedPrices = formModal.prices.map((price, i) =>
                i === index ? { ...price, price: newPrice } : price
            );
            setFormModal({ ...formModal, prices: updatedPrices });
        }
    };

    const handleDateRangePicker = (index, event, picker) => {
        setIsDateRangePickerEvent(false);
        const startDate = picker.startDate.format('yyyy/MM/DD');
        const endDate = picker.endDate.format('yyyy/MM/DD');
        if (props && props.editModal && Object.keys(props.editModal).length > 0) {
            const updatedEditModal = { ...props.editModal };
            const updatedEditPrices = [...props.editModal.prices];

            updatedEditPrices[index].dateRange = {
                from: new Date(startDate),
                to: new Date(endDate),
            };

            updatedEditModal.prices = updatedEditPrices;
            props.setEditModal(updatedEditModal);
        } else {
            const updatedFormModal = { ...formModal };
            const updatedPrices = [...formModal.prices];

            updatedPrices[index].dateRange = {
                from: new Date(startDate),
                to: new Date(endDate),
            };

            updatedFormModal.prices = updatedPrices;
            setFormModal(updatedFormModal);
        }
    };

    const onSubmitHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateRangePickerEvent) {
            return;
        }

        const form = e.currentTarget;
        const isEditModal = props && props.editModal && Object.keys(props.editModal).length > 0;

        if (form.checkValidity() === false || (isEditModal && props.editModal.facilities.length === 0) || (!isEditModal && formModal.facilities.length === 0) || (isEditModal && props.editModal.thumbnails.length === 0) || (!isEditModal && formModal.thumbnails.length === 0)) {
            setValidated(true);
            return;
        }

        const method = isEditModal ? 'patch' : 'post';
        const endpoint = isEditModal ? `${API_BASE_URL}rooms/${props.editModal._id}` : `${API_BASE_URL}rooms`;
        let formData = isEditModal ? { ...props.editModal } : formModal;
        if (isEditModal && formData) {
            delete formData._id;
            delete formData.createdAt;
            delete formData.updatedAt;
            delete formData.bookingStatus;
            delete formData.__v;
            delete formData?.roomCapacity?.id;
            delete formData?.roomCapacity?.roomId;
        }

        if (isEditModal && formData && (formData.roomCapacity || formData.hotelId)) {
            formData = {
                ...formData,
                capacity: formData.roomCapacity,
                userId: formData.userId._id,
                hotelId: formData.hotelId._id
            };
            delete formData.roomCapacity;
        }

        axios[method](endpoint, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 201) {
                setApiResponse({ type: "success", message: "New room created successfully!" });
                props.setRoomAdded(true);
                props.onHide();
            } else if (res.status === 200) {
                setApiResponse({ type: "info", message: "Room updated successfully!" });
                props.setRoomUpdated(true);
            }
            setSearchFacilities([]);
            setFormModal(getRoomInitalState());
        }).catch(err => {
            setApiResponse({ type: "danger", message: err?.response?.data?.message });
            console.error(err);
        });
    };

    useEffect(() => {
        if (!props.show) {
            setApiResponse({});
            console.log("api response state set to  {}");
        }
    }, [props.show]);

    useEffect(() => {
        if (isDateRangePickerEvent) {
            document.body.style.overflowY = 'scroll';
            return () => {
                document.body.style.overflowY = 'auto';
            };
        }
    }, [isDateRangePickerEvent]);

    useEffect(() => {
        if (props && props.editModal && Object.keys(props.editModal).length > 0) {
            console.log("props", props?.editModal)
        }
    }, [props]);

    const removeSepecificThumbnail = (e, key, from) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("key", key);
        if (from === 'add') {
            setFormModal((prevFormModal) => ({
                ...prevFormModal,
                thumbnails: prevFormModal.thumbnails.filter((_, index) => index !== key),
            }));
        } else if (from === 'edit') {
            const updatedEditModal = { ...props.editModal };
            updatedEditModal.thumbnails.splice(key, 1);
            props.setEditModal(updatedEditModal);
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Form noValidate validated={validated} onSubmit={onSubmitHandle}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props && props.editModal && Object.keys(props.editModal).length > 0 ? "Edit Room" : "Add Room"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                    <Row className='p-3'>
                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup className={``}>
                                <FormLabel>Select Photo <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <div {...getRootProps({ className: `dropzone-container dropzone ${validated && (props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.thumbnails.length === 0 ? 'invalid' : '' : formModal.thumbnails.length === 0 ? 'invalid' : '')}` })}>
                                    <input {...getInputProps()} />
                                    {props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                        props.editModal.thumbnails && props.editModal.thumbnails.length === 0 ?
                                            <Row>
                                                <Col xs={12} md={4} style={{ textAlign: "left" }}>
                                                    <Image src="./../images/no-image.png" width={119} height={120} />
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <span className='text-muted'>
                                                        Drag & Drop <br /> <span>OR</span> <br /> <span className='text-danger'>Browse Photo</span> <br /><br /> Supports: *.png, *.jpg and *.jpeg
                                                    </span>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className='d-flex' style={{ overflow: 'auto' }}>
                                                    {props.editModal.thumbnails.map((thumbnail, key) => (
                                                        <div key={key} style={{ position: "relative" }}>
                                                            <button
                                                                style={{ position: 'absolute', right: 10, borderRadius: 15, border: 0, background: 'transparent', color: 'white' }}
                                                                onClick={(e) => removeSepecificThumbnail(e, key, 'edit')}
                                                            >
                                                                <FaTimes />
                                                            </button>
                                                            <Image src={thumbnail} width={119} height={120} style={{ border: '1px solid #e5e5e5', borderRadius: 15, marginRight: 10 }} />
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                        :
                                        formModal.thumbnails && formModal.thumbnails.length === 0 ?
                                            <Row>
                                                <Col xs={12} md={4} style={{ textAlign: "left" }}>
                                                    <Image src="./../images/no-image.png" width={119} height={120} />
                                                </Col>
                                                <Col xs={12} md={8}>
                                                    <span className='text-muted'>
                                                        Drag & Drop <br /> <span>OR</span> <br /> <span className='text-danger'>Browse Photo</span> <br /><br /> Supports: *.png, *.jpg and *.jpeg
                                                    </span>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className='d-flex' style={{ overflow: 'auto' }}>
                                                    {formModal.thumbnails.map((thumbnail, key) => (
                                                        <div key={key} style={{ position: "relative" }}>
                                                            <button
                                                                style={{ position: 'absolute', right: 10, zIndex: 99999999999, borderRadius: 15, border: 0, background: 'transparent', color: 'white' }}
                                                                onClick={(e) => removeSepecificThumbnail(e, key, 'add')}
                                                            >
                                                                <FaTimes />
                                                            </button>
                                                            <Image src={thumbnail} width={119} height={120} style={{ border: '1px solid #e5e5e5', borderRadius: 15, marginRight: 10 }} />
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                    }
                                    {uploading && <span className='uploading-overlay'>Image Uploading...</span>}
                                </div>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Name <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Enter room name'
                                    required
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props?.editModal?.title : formModal.title}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, title: e.target.value }) : (e) => setFormModal({ ...formModal, title: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Hotels <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormSelect
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.hotelId._id : formModal.hotelId}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, hotelId: e.target.value }) : (e) => setFormModal({ ...formModal, hotelId: e.target.value })}
                                    required
                                >
                                    {hotels && hotels.length > 0 && hotels.map((hotel) => (
                                        <option value={hotel.id}>{hotel.name}</option>
                                    ))}
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Room Number <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Enter room number'
                                    required
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.roomNumber : formModal.roomNumber}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, roomNumber: e.target.value }) : (e) => setFormModal({ ...formModal, roomNumber: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Bed Type <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormSelect
                                    placeholder='Select Type'
                                    required
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.bedType : formModal.bedType}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, bedType: e.target.value }) : (e) => setFormModal({ ...formModal, bedType: e.target.value })}
                                >
                                    <option value="single">Single</option>
                                    <option value="double">Double</option>
                                    <option value="triple">Triple</option>
                                    <option value="other">other</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Floor <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Number'
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.floor : formModal.floor}
                                    required
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, floor: e.target.value }) : (e) => setFormModal({ ...formModal, floor: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Status <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormSelect
                                    placeholder='Select Status'
                                    required
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props.editModal.status : formModal.status}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (e) => props.setEditModal({ ...props.editModal, status: e.target.value }) : (e) => setFormModal({ ...formModal, status: e.target.value })}
                                >
                                    <option value="available">Available</option>
                                    <option value="booked">Booked</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Facilities <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <ReactSelect
                                    required
                                    isSearchable
                                    isMulti
                                    options={searchFacilities}
                                    placeholder="Search facilities..."
                                    onInputChange={handleInputChange}
                                    onChange={props && props.editModal && Object.keys(props.editModal).length > 0 ? (selectedOptions) => props.setEditModal({ ...props.editModal, facilities: selectedOptions }) : (selectedOptions) => setFormModal({ ...formModal, facilities: selectedOptions })}
                                    className={`${validated && formModal.facilities.length === 0 ? 'invalid' : ''}`}
                                    value={props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                        props.editModal.facilities && props.editModal.facilities.length > 0 ? props.editModal.facilities.map(item => ({
                                            value: item._id,
                                            label: item.title
                                        })) : []
                                        :
                                        formModal.facilities
                                    }
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Capacity</FormLabel>
                                <div className='d-flex border' style={{ gap: '10px', borderRadius: 7, padding: 15 }}>
                                    <FormGroup>
                                        <FormLabel>Adult</FormLabel>
                                        <FormControl
                                            type="number"
                                            value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props?.editModal?.roomCapacity?.adult : formModal.capacity.adult}
                                            name="adult"
                                            onChange={
                                                props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                                    (e) => props.setEditModal({ ...props.editModal, roomCapacity: { ...props.editModal.roomCapacity, [e.target.name]: e.target.value } }) :
                                                    (e) => setFormModal({ ...formModal, capacity: { ...formModal.capacity, [e.target.name]: e.target.value } })
                                            }
                                            placeholder='Adult'
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Children</FormLabel>
                                        <FormControl
                                            type="number"
                                            value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props?.editModal?.roomCapacity?.children : formModal.capacity.children}
                                            name="children"
                                            onChange={
                                                props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                                    (e) => props.setEditModal({ ...props.editModal, roomCapacity: { ...props.editModal.roomCapacity, [e.target.name]: e.target.value } }) :
                                                    (e) => setFormModal({ ...formModal, capacity: { ...formModal.capacity, [e.target.name]: e.target.value } })
                                            }
                                            placeholder='Children'
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Infant</FormLabel>
                                        <FormControl
                                            type="number"
                                            value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props?.editModal?.roomCapacity?.infant : formModal.capacity.infant}
                                            name="infant"
                                            onChange={
                                                props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                                    (e) => props.setEditModal({ ...props.editModal, roomCapacity: { ...props.editModal.roomCapacity, [e.target.name]: e.target.value } }) :
                                                    (e) => setFormModal({ ...formModal, capacity: { ...formModal.capacity, [e.target.name]: e.target.value } })
                                            }
                                            placeholder='Infant'
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>Overall</FormLabel>
                                        <FormControl
                                            type="text"
                                            value={props && props.editModal && Object.keys(props.editModal).length > 0 ? props?.editModal?.roomCapacity?.overall : formModal.capacity.overall}
                                            name="overall"
                                            onChange={
                                                props && props.editModal && Object.keys(props.editModal).length > 0 ?
                                                    (e) => props.setEditModal({ ...props.editModal, roomCapacity: { ...props.editModal.roomCapacity, [e.target.name]: e.target.value } }) :
                                                    (e) => setFormModal({ ...formModal, capacity: { ...formModal.capacity, [e.target.name]: e.target.value } })
                                            }
                                            placeholder='Overall'
                                            required
                                        />
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </Col>

                        {/* <Col xs={12} md={4} className='mb-2'>
                            <FormGroup>
                                <FormCheck label="Option 1 there is any need" className='text-muted' />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={4} className='mb-2'>
                            <FormGroup>
                                <FormCheck label="Option 2 there is any need" className='text-muted' />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={4} className='mb-2'>
                            <FormGroup>
                                <FormCheck label="Option 3 there is any need" className='text-muted' />
                            </FormGroup>
                        </Col> */}

                        <Col xs={12} md={12} className="mb-2">
                            <label>Prices</label>
                            {props && props.editModal && Object.keys(props.editModal).length > 0 ? formModal.prices && formModal.prices.length > 0 && props.editModal.prices.map((price, key) => (
                                <Row className='dropzone bg-transparent p-0 mb-3' key={key}>
                                    <Col xs={11} md={11} className='p-4'>
                                        <Row>
                                            <label className='text-light-gray align-left'>{price.seasonName}</label>
                                            <Col xs={12} md={6} className='align-left'>
                                                <FormGroup>
                                                    <FormLabel>Date Range <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                                    <DateRangePicker
                                                        initialSettings={{ startDate: new Date(), endDate: '1/1/2100' }}
                                                        onApply={(event, picker) => handleDateRangePicker(key, event, picker)}
                                                        onEvent={(event, picker) => {
                                                            if (event.type === 'show' || event.type === 'showCalendar ') {
                                                                setIsDateRangePickerEvent(true);
                                                            } else if (event.type === 'hide') {
                                                                setIsDateRangePickerEvent(false);
                                                            }
                                                        }}
                                                    >
                                                        <button className='form-control' style={{ textAlign: 'left' }}>
                                                            <span className='text-muted'>{cutomDateTimeFormat('date', price.dateRange.from, true, false)} - {cutomDateTimeFormat('date', price.dateRange.to, true, false)}</span>
                                                        </button>
                                                    </DateRangePicker>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6} className="align-left">
                                                <FormGroup>
                                                    <FormLabel>Price <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                                    <InputGroup>
                                                        <FormControl
                                                            aria-label="Dollar amount (with dot and two decimal places)"
                                                            placeholder='1,000'
                                                            value={price.price}
                                                            onChange={(e) => handlePriceChange(key, e.target.value)}
                                                        />
                                                        <InputGroup.Text>{price.currencySymbol}</InputGroup.Text>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={1} md={1} className="addRoom-remove-season-btn">
                                        <Link to={'#'} onClick={() => handleRemoveSeason(key)}><Image src='./../images/icons/delete.png' /></Link>
                                    </Col>
                                </Row>
                            )) : formModal.prices.map((price, key) => (
                                <Row className='dropzone bg-transparent p-0 mb-3' key={key}>
                                    <Col xs={11} md={11} className='p-4'>
                                        <Row>
                                            <label className='text-light-gray align-left'>{price.seasonName}</label>
                                            <Col xs={12} md={6} className='align-left'>
                                                <FormGroup>
                                                    <FormLabel>Date Range <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                                    <DateRangePicker
                                                        initialSettings={{ startDate: new Date(), endDate: '1/1/2100' }}
                                                        onApply={(event, picker) => handleDateRangePicker(key, event, picker)}
                                                        onEvent={(event, picker) => {
                                                            if (event.type === 'show' || event.type === 'showCalendar ') {
                                                                setIsDateRangePickerEvent(true);
                                                            } else if (event.type === 'hide') {
                                                                setIsDateRangePickerEvent(false);
                                                            }
                                                        }}
                                                    >
                                                        <button className='form-control' style={{ textAlign: 'left' }}>
                                                            <span className='text-muted'>{cutomDateTimeFormat('date', price.dateRange.from, true, false)} - {cutomDateTimeFormat('date', price.dateRange.to, true, false)}</span>
                                                        </button>
                                                    </DateRangePicker>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={6} className="align-left">
                                                <FormGroup>
                                                    <FormLabel>Price <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                                    <InputGroup>
                                                        <FormControl
                                                            aria-label="Dollar amount (with dot and two decimal places)"
                                                            placeholder='1,000'
                                                            value={price.price}
                                                            onChange={(e) => handlePriceChange(key, e.target.value)}
                                                        />
                                                        <InputGroup.Text>{price.currencySymbol}</InputGroup.Text>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={1} md={1} className="addRoom-remove-season-btn">
                                        <Link to={'#'} onClick={() => handleRemoveSeason(key)}><Image src='./../images/icons/delete.png' /></Link>
                                    </Col>
                                </Row>
                            ))}
                            <div className="d-grid gap-2">
                                <Button variant="light" size="lg" style={{ background: "#E9ECEF" }} onClick={handleAddNewSeason}>
                                    Add New Season
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-light" className='text-dark' onClick={props.onHide}>Discard</Button>
                    <Button variant='danger' type='submit' className='bg-custom-variant'>
                        {props && props.editModal && Object.keys(props.editModal).length > 0 ? "Update Room" : "Add Room"}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}