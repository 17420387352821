import AppRoutes from "./routes";
import './Fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css';
import 'react-calendar/dist/Calendar.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-input-range/lib/css/index.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;