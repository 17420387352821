import ReactRating from 'react-rating';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const RenderStar = ({ count, activeColor, disableColor }) => {
    
    return (
        <ReactRating
            initialRating={count}
            emptySymbol={<AiOutlineStar style={{ color: disableColor }}/>}
            readonly
            fullSymbol={<AiFillStar style={{ color: activeColor }} />} />
    );
}

export default RenderStar;