import { Navigate, Outlet } from "react-router-dom";

const RoleProtected = ({ user, allowedRoles, redirectPath = '/dashboard', children }) => {
    if (!user || !allowedRoles.includes(user.role)) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default RoleProtected;