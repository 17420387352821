import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, FormControl, Image, InputGroup, Row, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../endpoints";
import axios from "axios";

const Cart = ({ cart, removeItemFromCart, updateCartQuantity, setUserCoupon }) => {
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const [inputCouponValue, setInputCouponValue] = useState('');
    const [coupon, setCoupon] = useState({});
    const [currencySymbol, setCurrencySymbol] = useState('Rs');
    const calculateSubTotal = () => {
        if (cart.length > 0) {
            return cart.reduce((total, item) => {
                return total + item.quantity * item.bookingAmount;
            }, 0);
        } else {
            return 0;
        }
    };

    const calculateTotal = () => {
        if (cart && cart.length > 0) {
            return cart.reduce((total, item) => {
                return total + item.quantity * item.bookingAmount;
            }, 0);
        } else {
            return 0;
        }
    }

    const applyCoupon = () => {
        axios.post(`${API_BASE_URL}coupons/coupon-by-code`, { code: inputCouponValue }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                setCoupon(res.data);
                setUserCoupon(res.data);
            }
        }).catch(err => {
            console.log("Error in applyCoupon: ", err);
        })
    }

    useEffect(() => {
        calculateTotal();
    }, [coupon])

    useEffect(() => {
        if (cart && cart.length > 0) {
            setCurrencySymbol(cart[0].currencySymbol);
        }
    }, [cart]);

    return (
        <Container className="cart-container">
            {cart && cart.length > 0 ?
                <Row>
                    <Col xs={12} md={8}>
                        <Card className="remove-boder-and-radius">
                            <Card.Body className="padding-lr-0">
                                <Table responsive border={0}>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Subtotal</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cart.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="d-flex">
                                                        <Image src={Object.keys(item.roomDetail).length > 0 && item.roomDetail.thumbnails && item.roomDetail.thumbnails.length > 0 ? item.roomDetail.thumbnails[0] : '/images/no-image.png'} width={50} height={50} className="rounded-circle" />
                                                        <div style={{ fontSize: 12, marginLeft: 10 }}>
                                                            <h6>{Object.keys(item.roomDetail).length > 0 ? item.roomDetail.title : '-'}</h6>
                                                            <p className="mb-0">
                                                                <strong>Start: </strong>
                                                                <span className="text-muted">
                                                                    {item.orderDate && format(parse(item.orderDate.checkIn, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')}
                                                                </span>
                                                            </p>
                                                            <p className="mb-0">
                                                                <strong>End: </strong>
                                                                <span className="text-muted">
                                                                    {item.orderDate && format(parse(item.orderDate.checkOut, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <strong>{`${item.currencySymbol}${(item.bookingAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</strong>
                                                </td>
                                                <td>
                                                    <FormControl type="number" value={item.quantity} onChange={(e) => updateCartQuantity(key, e.target.value)} style={{ maxWidth: 100 }} />
                                                </td>
                                                <td>
                                                    <strong>
                                                        {`${item.currencySymbol}${(item.quantity * item.bookingAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <Button variant="outline-secondary" className="rounded-circle" onClick={() => removeItemFromCart(key)}>
                                                        <FaTrashAlt />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={4}>
                        <Card className="remove-boder-and-radius">
                            <Card.Body className="padding-lr-0">
                                <h4 className="padding-lr-25">Cart totals</h4>
                                <hr />
                                <div className="d-flex justify-content-space-between padding-lr-25">
                                    <h5>Subtotal</h5>
                                    <h5>{`${currencySymbol}${calculateSubTotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</h5>
                                </div>
                                <hr />
                                <h5 className="padding-lr-25">Coupon Code</h5>
                                <InputGroup className="mb-3 padding-lr-25">
                                    <FormControl
                                        placeholder="Coupon code"
                                        aria-label="Coupon code"
                                        aria-describedby="basic-addon2"
                                        size="lg"
                                        type="text"
                                        value={inputCouponValue}
                                        onChange={(e) => setInputCouponValue(e.target.value)}
                                    />
                                    <InputGroup.Text id="basic-addon2" onClick={() => applyCoupon()}>Apply Coupon</InputGroup.Text>
                                </InputGroup>
                                <hr />
                                <div className="d-flex justify-content-space-between mb-5 padding-lr-25">
                                    <h5>Total</h5>
                                    <h5>{`${currencySymbol}${Object.keys(coupon).length > 0 ? calculateTotal() - (coupon.type === 'number' ? coupon.amount : calculateTotal() / 100 * coupon.amount).toFixed(1) : calculateTotal().toFixed(1)}`}</h5>
                                </div>
                                <div className="d-grid gap-2 padding-lr-25">
                                    <Button variant="danger" size="lg" className="btn-proceed-to-checkout" as={Link} to={"/place-order"}>
                                        Proceed to checkout
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                :
                <Card className="no-border no-border-radius">
                    <Card.Body>
                        <span className="text-danger p-3">Cart is empty!</span>
                    </Card.Body>
                </Card>
            }
        </Container>
    );
}

const mapStateToProps = (state) => ({
    cart: state.landingPagesInitialState.cart,
});
const dispatchStateToProps = (dispatch) => ({
    removeItemFromCart: (key) => {
        dispatch({
            type: "REMOVE_ITEM_FROM_CART",
            payload: key
        })
    },
    updateCartQuantity: (key, value) => {
        dispatch({
            type: "UPDATE_CART_QUANTITY",
            payload: { key: key, quantity: value }
        })
    },
    setUserCoupon: (obj) => {
        dispatch({
            type: "UPDATE_USER_COUPON",
            payload: obj
        });
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(Cart);
