import { Alert, Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeadingUnderline from "../includes/HeadingUnderline";
import { FaAngleRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from './../../endpoints';

export default function ContactUs() {
    const [apiResponse, setApiResponse] = useState({});
    const [offices, setOffices] = useState([]);
    const [validated, setValidated] = useState(false);
    const [formModal, setFormModal] = useState({
        name: '',
        email: '',
        message: ''
    });
    const getContactUs = () => {
        axios.get(`${API_BASE_URL}landing/contact-us`, { headers: { "Content-Type": 'application/json' } }).then(res => {
            if (res.status === 200) {
                setOffices(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        setApiResponse({});
        getContactUs();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        axios.post(`${API_BASE_URL}landing/save-message`, formModal, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if(res.status === 200) {
                setApiResponse(res.data);
                setFormModal({
                    name: '',
                    email: '',
                    message: ''
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <div className="mb-5 contact-us--heading-container">
                <h2 className="mb-0 text-white">Contact Us</h2>
                <div>
                    <span className="text-white font-12">Home</span>
                    <span className="text-white font-12"><FaAngleRight /></span>
                    <span className="text-white font-12">Contact Us</span>
                </div>
            </div>

            <div className="mb-5 contact-us--content-container">
                <Row>
                    {offices && offices.length > 0 && offices.map((office, key) => (
                        <Col xs={12} md={4} key={key}>
                            <h4>{office.name}</h4>
                            <p className="mb-0" style={{ width: "70%" }}>{office.address}</p>
                            <br />
                            <p className="mb-0">{office.phone}</p>
                            <p className="mb-0">{office.email}</p>
                            <Link to={"#"} className="custom-text-color"><u>View On Map</u></Link>
                        </Col>
                    ))}
                </Row>

                <Row className="mt-3">
                    <Col>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <h4 className="mb-3 text-center">Send a Message</h4>
                            <HeadingUnderline />
                            {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                            <Row className="mb-3">
                                <Col xs={12} md={6}>
                                    <FormControl
                                        type="text"
                                        placeholder="Name"
                                        value={formModal.name}
                                        required
                                        onChange={e => setFormModal({ ...formModal, name: e.target.value })}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormControl 
                                        type="email" 
                                        placeholder="Email" 
                                        value={formModal.email}
                                        required
                                        onChange={e => setFormModal({ ...formModal, email: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12}>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3}
                                        value={formModal.message}
                                        required
                                        onChange={e => setFormModal({ ...formModal, message: e.target.value })} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Button variant="danger" type="submit" className="bg-theme-color">Send Message</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div >
            <div className="mb-5">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1563311.79402627!2d-76.04358576191505!3d40.067752775516865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c0fb959e00409f%3A0x2cd27b07f83f6d8d!2sNew%20Jersey%2C%20USA!5e0!3m2!1sen!2s!4v1693468918646!5m2!1sen!2s"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    title="Location Google Map"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </>
    );
};