import { Breadcrumb, Button, Card, Carousel, Col, Container, Dropdown, FormCheck, Image, Row, Table } from "react-bootstrap";
import { currentBookings, rooms } from "../../../appData";
import { AiFillCloseCircle, AiOutlineInfoCircle, AiOutlineUser } from "react-icons/ai";
import SearchResultSingleItem from "../../includes/SearchResultSingleItem";
import { Link, useParams } from "react-router-dom";
import { BsFillCalendarFill, BsThreeDots } from "react-icons/bs";
import { BiSolidSortAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../endpoints";
import axios from "axios";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { formatDateString, formateOrderDateTime, getFormattedDate } from "../../../helpers/common-helper";

export default function GuestDetail() {
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const { userId } = useParams();
    const [itemsPerSlide, setItemsPerSlide] = useState(3);
    const [numSlides, setNumOfSlides] = useState(0);
    const [userData, setUserData] = useState({});

    const getUserData = () => {
        axios.get(`${API_BASE_URL}users/${userId}`, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                setUserData(res.data);
                if (Object.keys(res.data).length > 0 && Object.keys(res.data.currentBookings).length > 0 && res.data.currentBookings.thumbnails.length > 0) {
                    setNumOfSlides(Math.ceil(res.data.currentBookings.thumbnails.length / itemsPerSlide));
                }
            }
        }).catch(err => {
            console.log("Error: ", err);
        });
    }

    useEffect(() => {
        getUserData();
    }, [userId]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992) {
                setItemsPerSlide(3);
            } else {
                setItemsPerSlide(1);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Breadcrumb id="guest-detail-breadcrumb">
                <Breadcrumb.Item>Guest</Breadcrumb.Item>
                <Breadcrumb.Item>Mr. {Object.keys(userData).length > 0 && userData.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col xs={12} md={9}>
                    {Object.keys(userData).length > 0 && Object.keys(userData.currentBookings.thumbnails).length > 0 && userData.currentBookings.thumbnails.length ?
                        <Card className="no-border">
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col xs={12} md={12}>
                                        <h5 className="mb-2">Current Bookings</h5>
                                        <Carousel indicators={false} interval={5000}>
                                            {Array.from({ length: numSlides }).map((_, slideIndex) => (
                                                <Carousel.Item key={slideIndex}>
                                                    <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
                                                        <Row>
                                                            {userData.currentBookings.thumbnails.slice(
                                                                slideIndex * itemsPerSlide,
                                                                (slideIndex + 1) * itemsPerSlide
                                                            ).map((item, itemIndex) => (
                                                                <Col key={itemIndex} xs={12} md={4}>
                                                                    <a href="#" className="text-decoration-none">
                                                                        <Card className="no-border">
                                                                            <Card.Img variant="top" className='test' src={item} style={{ borderRadius: 25, height: 200 }} />
                                                                        </Card>
                                                                    </a>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Container>
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={12} md={4} className="d-flex align-items-center">
                                                <h5 className="mb-0 me-3">Room Facilites</h5>
                                                <AiOutlineInfoCircle className="text-warning" style={{ fontSize: 20 }} />
                                            </Col>
                                            <Col xs={12} md={8} style={{ textAlign: "right" }}>
                                                <span className="text-muted">{Object.keys(userData).length > 0 && userData.roomFacilities}</span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={6} md={2} className="text-center">
                                                <Image src="/images/room-facilities.svg" />
                                            </Col>
                                            <Col xs={6} md={2} className="d-flex align-items-center">
                                                <div>
                                                    <Link to={"#"} className="custom-text-color" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Booking ID #0000000</Link>
                                                    <br />
                                                    <strong>{Object.keys(userData).length > 0 && Object.keys(userData.currentBookings).length > 0 && userData.currentBookings.title}</strong>
                                                </div>
                                            </Col>
                                            <Col xs={6} md={3} className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <AiOutlineUser color="gray" className="me-2" />
                                                    <span className="text-muted">Room Capacity</span>
                                                    <br />
                                                    <strong>3-5 Person</strong>
                                                </div>
                                            </Col>

                                            <Col xs={6} md={2} className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <Image src="/images/bed.png" className="me-2" />
                                                    <span className="text-muted">Bed Type</span>
                                                    <br />
                                                    <strong>{Object.keys(userData).length > 0 && Object.keys(userData.currentBookings).length > 0 && userData.currentBookings.bedType}</strong>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={3} className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <BsFillCalendarFill color="gray" className="me-2" />
                                                    <span className="text-muted">Booking Date</span>
                                                    <br />
                                                    <strong>
                                                        {Object.keys(userData).length > 0 && Object.keys(userData.currentBookings).length > 0 && formatDateString(userData.currentBookings.checkIn) + ' - ' + formatDateString(userData.currentBookings.checkOut)}
                                                    </strong>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        :
                        <Card className="no-border">
                            <Card.Body>
                                <span className="text-danger">No currenct Booking found!</span>
                            </Card.Body>
                        </Card>
                    }
                </Col>

                <Col xs={12} md={3}>
                    <Card className="no-border">
                        <Card.Body>
                            <div className="d-flex mb-3">
                                <Image src={Object.keys(userData).length > 0 && userData.avatar} className="me-2" width={80} height={80} rounded />
                                <div className="d-flex justify-content-center" style={{ flexDirection: "column" }}>
                                    <Link to={"#"} className="custom-text-color">#{Object.keys(userData).length > 0 && userData.code}</Link>
                                    <strong>Mr. {Object.keys(userData).length > 0 && userData.name}</strong>
                                </div>
                            </div>
                            <div className="d-flex justify-content-space-evenly mb-3">
                                <Button className="edit-profile-btn text-dark">Edit Profile</Button>
                                <Button variant="danger" style={{ backgroundColor: "#FF6746" }}><AiFillCloseCircle /></Button>
                            </div>

                            <div className="d-flex mb-3 align-items-center">
                                <Button className="rounded-circle me-2" variant="outline-danger">
                                    <FaPhoneAlt />
                                </Button>
                                <span>+{Object.keys(userData).length > 0 && userData.phoneNumber}</span>
                            </div>
                            <div className="d-flex mb-3 align-items-center">
                                <Button className="rounded-circle me-2" variant="outline-danger"><FaEnvelope /></Button>
                                <span>{Object.keys(userData).length > 0 && userData.email}</span>
                            </div>
                            {Object.keys(userData).length > 0 && userData.address &&
                                <div className="d-flex mb-5">
                                    <div><Button className="rounded-circle" variant="outline-danger me-2"><FaEnvelope /></Button></div>
                                    <span>{Object.keys(userData).length > 0 && userData?.address}</span>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-3 mt-3">
                <Col xs={12} md={12}>
                    <Card className="no-border">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={8} className="mb-2"><h5 className="mb-0">History Booking</h5></Col>
                                <Col xs={12} md={4} className="mb-2" style={{ textAlign: "right" }}>
                                    <Button variant="danger" className="btn-custom-color me-3">
                                        <Image src="/images/date-filter-calendar.svg" />
                                        Date Filter
                                    </Button>
                                    <Button variant="danger" className="btn-custom-color">
                                        <Image src="/images/generate-report.svg" />
                                        Generate Report
                                    </Button>
                                </Col>
                            </Row>

                            <Table responsive striped hover border={"0px #e5e5e5"}>
                                <thead>
                                    <tr>
                                        <th><FormCheck /></th>
                                        <th><div className="d-flex align-items-center">Room Name <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                        <th><div className="d-flex align-items-center">Bed Type <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                        <th><div className="d-flex align-items-center">Room Floor <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                        <th><div className="d-flex align-items-center">Room Facilities <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                        <th><div className="d-flex align-items-center">Book date <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(userData).length > 0 && userData.history && userData.history.length > 0 ? userData.history.map((rm, key) => (
                                        <tr key={key}>
                                            <td><FormCheck /></td>
                                            <td style={{ width: 350 }}>
                                                <div className="d-flex align-items-center">
                                                    <Carousel indicators={false} className="roomSlider" style={{ width: 200 }}>
                                                        {rm.slider && rm.slider.length > 0 && rm.slider.map((slide, keyy) => (
                                                            <Carousel.Item key={keyy}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    style={{ height: 100 }}
                                                                    src={slide}
                                                                    alt="Second slide"
                                                                />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>

                                                    <div className="d-flex" style={{ flexDirection: "column", marginLeft: 15 }}>
                                                        <Link to={"#"} className="custom-text-color">{rm.code}</Link>
                                                        <strong>{rm.title}</strong>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ padding: 15 }}>
                                                <p>{rm.bedType}</p>
                                            </td>
                                            <td>
                                                <strong>{rm.floor}</strong>
                                            </td>
                                            <td style={{ maxWidth: 200 }}>
                                                {rm.facilities}
                                            </td>
                                            <td>
                                                <strong>{formateOrderDateTime('date', rm.bookedDate)}</strong><br />
                                                <span>{formateOrderDateTime('time', rm.bookedDate)}</span>
                                            </td>
                                            <td>
                                                <div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : 
                                        <tr>
                                            <td colSpan={7}>
                                                <span className="text-danger">No recrod found!</span>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    );
}