import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import { connect } from 'react-redux';

const CartIconWithBadge = ({ cart }) => {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <FontAwesomeIcon icon={faBasketShopping} />

            {cart && cart.length > 0 && (
                <Badge pill className="badge-counter bg-danger">
                    {cart.length}
                </Badge>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    cart: state.landingPagesInitialState.cart
});

export default connect(mapStateToProps, {})(CartIconWithBadge);