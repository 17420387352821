import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Image, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { API_BASE_URL } from '../../../endpoints';
import { doUpload } from '../../../helpers/uploads';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { formatDateString, getFormattedDate } from '../../../helpers/common-helper';

export default function Add(props) {
    const token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    const userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [isDateRangePickerEvent, setIsDateRangePickerEvent] = useState(false);
    const [apiResponse, setApiResponse] = useState({});
    const [validated, setValidated] = useState(false);
    const [formModal, setFormModal] = useState({
        userId: userId,
        avatar: '',
        name: '',
        email: '',
        code: '',
        phoneNumber: '',
        password: 'password1',
        role: 'employee',
        roomType: 'single',
        schedule: {
            start: 'Aug 26/2024',
            end: 'Aug 27/2024',
            formatedStart: getFormattedDate(new Date(), 'MM/DD/YYYY'),
            formatedEnd: getFormattedDate(new Date(), 'MM/DD/YYYY')
        },
        jobDesk: ''
    });

    useEffect(() => {
        if (!props.show) {
            setApiResponse({});
            setValidated(false);
        }
    }, [props.show]);

    const onDrop = useCallback(async (selectedFiles) => {
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const uploadFile = async (file) => {
            if (!allowedTypes.includes(file.type)) {
                setApiResponse({ type: 'danger', message: 'Only JPG, JPEG, and PNG files are allowed.' });
                return;
            }

            try {
                const result = await doUpload(file);
                setFormModal({ ...formModal, avatar: result });
            } catch (error) {
                setApiResponse(error);
                console.error(error);
            }
        };

        for (const file of selectedFiles) {
            await uploadFile(file);
        }
    }, [formModal]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/jpg, image/png',
    });

    const onSubmitHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isDateRangePickerEvent) {
            return;
        }

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        let formBody = {
            userId: userId,
            avatar: formModal.avatar,
            name: formModal.name,
            email: formModal.email,
            code: formModal.code,
            phoneNumber: formModal.phoneNumber,
            password: 'password1',
            role: 'employee',
            roomType: formModal.roomType,
            checkIn: new Date(formModal.schedule.formatedStart),
            checkOut: new Date(formModal.schedule.formatedEnd),
            jobDesk: formModal.jobDesk
        }

        axios.post(`${API_BASE_URL}auth/register`, formBody, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 201) {
                setApiResponse({ type: "success", message: "New Employee created successfully!" });
                setValidated(false);
                setFormModal({
                    userId: userId,
                    avatar: '',
                    name: '',
                    email: '',
                    code: '',
                    phoneNumber: '',
                    password: 'password1',
                    role: 'employee',
                    roomType: 'single',
                    schedule: {
                        start: 'Aug 26/2024',
                        end: 'Aug 27/2024',
                        formatedStart: getFormattedDate(new Date(), 'MM/DD/YYYY'),
                        formatedEnd: getFormattedDate(new Date(), 'MM/DD/YYYY')
                    },
                    jobDesk: ''
                });
                props.onHide();
            }
        }).catch(err => {
            setApiResponse({ type: "danger", message: err?.response?.data?.message });
            console.log(err);
        });
    }

    const handleDateRangeApply = (event, picker) => {
        setIsDateRangePickerEvent(false);
        const startDate = picker.startDate.format('MM/DD/YYYY');
        const endDate = picker.endDate.format('MM/DD/YYYY');

        setFormModal({
            ...formModal,
            schedule: {
                start: formatDateString(startDate),
                end: formatDateString(endDate),
                formatedStart: startDate,
                formatedEnd: endDate
            },
        });
    }

    const removeUploadedAvatar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFormModal({...formModal, avatar: ''});
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Form noValidate validated={validated} onSubmit={onSubmitHandle}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Employee
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {apiResponse && Object.keys(apiResponse).length > 0 && <Alert variant={apiResponse.type}>{apiResponse.message}</Alert>}
                    <Row className='p-3'>
                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Select Photo <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <div {...getRootProps({ className: `dropzone ${validated && !formModal.avatar ? 'invalid' : ''}` })}>
                                    <input {...getInputProps()} />
                                    {!formModal.avatar ?
                                        <Row>
                                            <Col xs={12} md={4} style={{ textAlign: "left" }}>
                                                <Image src="./../images/no-image.png" width={119} height={120} />
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <span className='text-muted'>
                                                    Drag & Drop <br /> <span>OR</span> <br /> <span className='text-danger'>Browse Photo</span> <br /><br /> Supports: *.png, *.jpg and *.jpeg
                                                </span>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className='d-flex' style={{ overflow: 'auto' }}>
                                                {formModal.avatar &&
                                                    <div style={{ position: "relative" }}>
                                                        <button
                                                            style={{ position: 'absolute', right: 10, borderRadius: 15, border: 0, background: 'transparent', color: 'white' }}
                                                            onClick={removeUploadedAvatar}
                                                        >
                                                            <FaTimes />
                                                        </button>
                                                        <Image src={formModal.avatar} width={119} height={120} style={{ border: '1px solid #e5e5e5', borderRadius: 15, marginRight: 10 }} />
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={12} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Name <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="text"
                                    required
                                    placeholder='Full Name'
                                    value={formModal.name}
                                    onChange={(e) => setFormModal({ ...formModal, name: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Code</FormLabel>
                                <FormControl
                                    type="text"
                                    placeholder='Employee Code'
                                    value={formModal.code}
                                    onChange={(e) => setFormModal({ ...formModal, code: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Number <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="phone"
                                    placeholder='0234421211'
                                    value={formModal.phoneNumber}
                                    required
                                    onChange={(e) => setFormModal({ ...formModal, phoneNumber: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Email <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    type="email"
                                    placeholder='jhon@example.com'
                                    required
                                    value={formModal.email}
                                    onChange={(e) => setFormModal({ ...formModal, email: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup>
                                <FormLabel>Room Type</FormLabel>
                                <FormSelect placeholder='Select Room Type' value={formModal.roomType} onChange={(e) => setFormModal({ ...formModal, roomType: e.target.value })}>
                                    <option value='single'>Single</option>
                                    <option value='double'>Double</option>
                                    <option value='triple'>Triple</option>
                                    <option value='others'>other</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup hasValidation>
                                <FormLabel>Schedule</FormLabel>
                                <DateRangePicker
                                    initialSettings={{ startDate: new Date(), endDate: '1/1/2050' }}
                                    onApply={handleDateRangeApply}
                                    onEvent={(event, picker) => {
                                        if (event.type === 'show' || event.type === 'showCalendar ') {
                                            setIsDateRangePickerEvent(true);
                                        } else if (event.type === 'hide') {
                                            setIsDateRangePickerEvent(false);
                                        }
                                    }}
                                >
                                    <button className='form-control' style={{ textAlign: 'left' }}>
                                        <span style={{ marginLeft: 15 }} className='text-muted'>
                                            {`${formModal.schedule.start}-${formModal.schedule.end}`}
                                        </span>
                                    </button>
                                </DateRangePicker>
                            </FormGroup>
                        </Col>

                        <Col xs={12} md={6} className='mb-2'>
                            <FormGroup hasValidation>
                                <FormLabel>Job Desk <span className='required'>{" * "}</span><FaInfoCircle color='#e5e5e5' /></FormLabel>
                                <FormControl
                                    value={formModal.jobDesk}
                                    onChange={(e) => setFormModal({ ...formModal, jobDesk: e.target.value })}
                                    placeholder="Job Desk"
                                    className="form-control"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                    <Button variant="outline-light" className='text-dark' onClick={props.onHide}>Discard</Button>
                    <Button variant='danger' className='bg-custom-variant' type="submit">Add Employee</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}