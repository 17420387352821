import { Button, Col, Dropdown, FormCheck, FormControl, Image, Row, Table } from "react-bootstrap";
import { BiSolidSortAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import Add from "./add";
import { API_REQUEST_HEADER, DELETE_HOTEL_ENDPOINT, GET_HOTELS_ENDPOINT } from "../../../endpoints";
import MyPagination from "../rooms/room-pagination";
import axios from "axios";
import { capitalizeFirstLetter, cutomDateTimeFormat } from "../../../helpers/common-helper";
import Loading from "../../loading";
import SpecialRequestModal from "./notes";

export default function Hotels() {
    const [loading, setLoading] = useState(true);
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [tabActiveKey, setTabActiveKey] = useState('allHotels');
    const [showAddModal, setAddShowModal] = useState(false);
    const [showSpecialRequestModal, setShowSpecialRequestModal] = useState(false);
    const [hotelList, setHotelList] = useState({});
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterStatus, setFilterStatus] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [selectedItem, setSelectedItem] = useState({});
    const statusColors = {
        refund: 'text-danger',
        canceled: 'text-muted',
        pending: 'text-warning',
        booked: 'text-success'
    };

    useEffect(() => {
        fetchApiData(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit, showAddModal]);

    const fetchApiData = (userId, limit, page) => {
        setLoading(true);
        axios.get(GET_HOTELS_ENDPOINT(userId, limit, page), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setHotelList(response.data);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const handlePageChange = (pageNumber) => {
        fetchApiData(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    const deleteHotel = (id) => {
        axios.delete(`${DELETE_HOTEL_ENDPOINT}/${id}`, API_REQUEST_HEADER()).then((response) => {
            if (response.status === 204) {
                fetchApiData(userId, pageLimit, selectedPageNumber);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        })
    }

    const handleViewNoteClick = (item) => {
        setSelectedItem(item);
        setShowSpecialRequestModal(true);
    }

    return (
        <>
            {loading ? <Loading />
                :
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <ul className="guest-tabs">
                                <li className={tabActiveKey === 'allHotels' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('allHotels'); setFilterStatus('all') }}>All Hotels ({hotelList?.totalResults})</Link></li>
                                <li className={tabActiveKey === 'pending' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('pending'); setFilterStatus('pending') }}>Pending ({hotelList?.pendingHotelsCount})</Link></li>
                                <li className={tabActiveKey === 'booked' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('booked'); setFilterStatus('booked') }}>Booked ({hotelList?.bookedHotelsCount})</Link></li>
                                <li className={tabActiveKey === 'canceled' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('canceled'); setFilterStatus('canceled') }}>Canceled ({hotelList?.canceledHotelsCount})</Link></li>
                                <li className={tabActiveKey === 'refund' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('refund'); setFilterStatus('refund') }}>Refund ({hotelList?.refundHotelsCount})</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={4} className="custom-column-style" style={{ display: "flex", justifyContent: 'flex-end', alignItems: "flex-start" }}>
                            <div className="add-room--search-container">
                                <div className="add-room--search-inner-container">
                                    <FormControl
                                        type="text"
                                        placeholder="Search here"
                                        className="make-beautify me-2 input-lg"
                                        aria-label="Search"
                                        style={{ minWidth: 350, paddingRight: 30, caretColor: '#F14778' }}
                                        value={searchText}
                                        onChange={(event) => setSearchText(event.target.value)}
                                    />
                                    <div style={{ position: "relative", right: 35, top: 0 }}>
                                        <Image src='./../images/icons/search.png' width={14} height={14} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="danger" className="btn-custom-color add-new-room-btn fontSize-14 btn-lg" onClick={() => setAddShowModal(true)} style={{ alignSelf: "center" }}><AiOutlinePlus /> Add Hotel</Button>
                        </Col>
                    </Row>

                    <div className="guest-tabs-contents">
                        <Table striped responsive hover style={{ backgroundColor: "white" }} border={"0px #e5e5e5"}>
                            <thead>
                                <tr>
                                    <th><FormCheck /></th>
                                    <th><div className="d-flex align-items-center">Name <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Arrival Date & Time <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Pickup <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Departure <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Special Request <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Room Type <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Status <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {hotelList && hotelList.hotels && hotelList.hotels.length > 0
                                    ? hotelList.hotels
                                        .filter((item) => {
                                            const lowercaseStatus = item.status.toLowerCase();
                                            return (
                                                (filterStatus === 'all' ||
                                                    (filterStatus === 'pending' && lowercaseStatus === 'pending') ||
                                                    (filterStatus === 'booked' && lowercaseStatus === 'booked') ||
                                                    (filterStatus === 'refund' && lowercaseStatus === 'refund') ||
                                                    (filterStatus === 'canceled' && lowercaseStatus === 'canceled')) &&
                                                ((item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
                                                    item.code.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    lowercaseStatus.includes(searchText.toLowerCase()))
                                            );
                                        })
                                        .map((item, key) => (
                                            <tr key={key}>
                                                <td><FormCheck /></td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <Image src={`${item.thumbnails && item.thumbnails.length > 0 && item.thumbnails[0]}`} className="me-2" width={60} height={60} rounded />
                                                        <div className="d-flex" style={{ flexDirection: "column" }}>
                                                            <Link to={"#"} className="custom-text-color">{item.code}</Link>
                                                            <strong>{item.name}</strong>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ padding: 15, verticalAlign: "middle" }}>
                                                    <strong>{cutomDateTimeFormat('date', item.arrivalDateTime.date)}</strong>
                                                    <br />
                                                    <span>{cutomDateTimeFormat('time', item.arrivalDateTime.time)}</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle", fontWeight: 600 }}>{capitalizeFirstLetter(item.pickup)}</td>
                                                <td style={{ maxWidth: 200, verticalAlign: "middle" }}>
                                                    <strong>{cutomDateTimeFormat('date', item.departureDate.date)}</strong>
                                                    <br />
                                                    <span>{cutomDateTimeFormat('time', item.departureDate.time)}</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle", textAlign: 'center' }}>
                                                    <span className="custom-text-color text-view-notes" onClick={() => handleViewNoteClick(item)}>View Notes</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>{item.roomType}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <span className={statusColors[item?.status.toLowerCase()]}>
                                                        {capitalizeFirstLetter(item.status)}
                                                    </span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                                                <Dropdown.Item onClick={() => deleteHotel(item.id)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td colSpan={9} className="text-center text-danger">No record found</td></tr>}
                            </tbody>
                        </Table>
                        <MyPagination list={hotelList?.hotels} totalPages={hotelList?.totalPages} page={hotelList?.page} pageLimit={pageLimit} totalResults={hotelList?.totalResults} onPageChange={handlePageChange} />
                    </div>

                    <Add show={showAddModal} onHide={() => setAddShowModal(false)} />
                    <SpecialRequestModal show={showSpecialRequestModal} onHide={() => setShowSpecialRequestModal(false)} selectedItem={selectedItem} />
                </>
            }
        </>
    );
}