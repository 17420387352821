import { Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthProtected from "./AuthProtected";
import RoleProtected from "./RoleProtected";

import AuthLayout from "../components/Layouts/AuthLayout";
import DashboardLayout from "../components/Layouts/DashboardLayout";

import Login from "../components/login/login";
import Signup from "../components/signup/singup";

import Dashboard from '../components/dashboard/dashboard';
import LandingLayout from "../components/Layouts/LandingLayout";
import Home from "../components/home/home";
import AllHotels from "../components/all-hotels/all-hotels";
import AboutUs from "../components/about-us/about-us";
import ContactUs from "../components/contact-us/contact-us";
import Cart from "../components/cart/cart";
import PlaceOrder from "../components/cart/place-order";
import HotelDetail from "../components/all-hotels/hotel-detail";
import Reviews from "../components/dashboard/reviews";
import Rooms from "../components/dashboard/rooms";
import Employee from "../components/dashboard/employee";
import Booking from "../components/dashboard/booking";
import Settings from "../components/dashboard/settings";
import SearchResults from "../components/dashboard/booking/search-results";
import BookingHotelDetail from "../components/dashboard/booking/detail";
import Tour from "../components/tour";
import TourDetail from "../components/tour/detail";
import Hotels from "../components/dashboard/hotels";
import Logout from "../components/logout";
import GuestList from "../components/dashboard/guest";
import GuestDetail from "../components/dashboard/guest/detail";

export default function AppRoutes() {
	let user = JSON.parse(localStorage.getItem("user"));
	let isLoggedIn = false;
	if (user && Object.keys(user).length > 0) {
		isLoggedIn = true;
	}
	
	return (
		<>
			<Router>
				<Routes>
					<Route element={<LandingLayout />}>
						<Route path="/" element={<Home />} />
						<Route path="/all-hotels" element={<AllHotels />} />
						<Route path="/tour" element={<Tour />} />
						<Route path="/tour-detail" element={<TourDetail />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/contact-us" element={<ContactUs />} />
						<Route path="/cart" element={<Cart />} />
						<Route path="/place-order" element={<PlaceOrder />} />
						<Route path="/hotel-detail/:hotelId" element={<HotelDetail />} />
					</Route>

					<Route element={<AuthLayout />}>
						<Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Login />} />
						<Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <Signup />} />
					</Route>

					{/* <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard/home" /> : <AuthLayout><Login /></AuthLayout>} />
					<Route path="/signup" element={<AuthLayout><Signup /></AuthLayout>} /> */}

					<Route element={<AuthProtected user={user} isLoggedIn={isLoggedIn} />}>
						<Route element={<DashboardLayout />}>
							<Route path="/dashboard" element={<Navigate to="/dashboard/home" />} title="Dashboard" />
							<Route path="/dashboard/home" element={<Dashboard />} title="Home" />
							<Route path="/dashboard/reviews" element={<Reviews />} title="Reviews" />
							<Route path="/dashboard/guest-list" element={<GuestList />} title="Guest List" />
							<Route path="/dashboard/guest-detail/:userId" element={<GuestDetail />} title="Guest Detail" />
							<Route path="/dashboard/rooms" element={<Rooms />} title="Rooms" />
							<Route path="/dashboard/employee" element={<Employee />} title="Employee" />
							<Route path="/dashboard/booking" element={<Booking />} title="Booking" />
							<Route path="/dashboard/hotels" element={<Hotels />} title="View Hotel" />
							<Route path="/dashboard/search-results" element={<SearchResults />} title="Search Results" />
							<Route path="/dashboard/search-hotel-detail" element={<BookingHotelDetail  />} title="Hotel Detail" />
							<Route path="/dashboard/settings" element={<Settings />} title="Settings" />
							<Route path="/dashboard/reviews" element={<Reviews />} title="Reviews" />
							<Route path="/dashboard/logout" element={<Logout />} />
							<Route path="/dashboard/inventory" element={<RoleProtected user={user} allowedRoles={['admin', 'manager', 'member']}><Reviews /></RoleProtected>} title="Inventory" />
						</Route>
					</Route>
				</Routes>
			</Router>
		</>
	);
}