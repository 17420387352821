// export const API_BASE_URL = 'http://localhost:8080/v1/';
export const API_BASE_URL = 'https://api.travelpinq.com/v1/';
export const API_REQUEST_HEADER = (hasThumbnail) => {
    let token = JSON.parse(localStorage.getItem("tokens"))?.access?.token;
    if (token && token !== undefined) {
        if (hasThumbnail) {
            return {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        } else {
            return {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        }
    }

    return {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
};
export const REGISTER_ENDPOINT = API_BASE_URL + 'auth/register';
export const LOGIN_ENDPOINT = API_BASE_URL + 'auth/login';

export const GET_ROOMS_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}rooms?userId=${param}&limit=${limit}&page=${page}`;
};

export const GET_BOOKNGS_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}bookings?userId=${param}&limit=${limit}&page=${page}`;
};

export const GET_HOTELS_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}hotels?userId=${param}&limit=${limit}&page=${page}`;
};

//http://localhost:8080/v1/users?role=guest&limit=10&page=1
export const GET_GUEST_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}guests?userId=${param}&limit=${limit}&page=${page}`;
};

export const GET_EMPLOYEE_ENDPOINT = (param, limit, page) => {
    return `${API_BASE_URL}users?userId=${param}&role=employee&limit=${limit}&page=${page}`;
};

export const DELETE_ROOM_ENDPOINT = `${API_BASE_URL}rooms`;
export const DELETE_BOOKING_ENDPOINT = `${API_BASE_URL}bookings`;
export const DELETE_GUEST_ENDPOINT = `${API_BASE_URL}users`;
export const DELETE_EMPLOYEE_ENDPOINT = `${API_BASE_URL}users`;
export const DELETE_HOTEL_ENDPOINT = `${API_BASE_URL}hotels`;
export const CREATE_HOTEL_ENDPOINT = `${API_BASE_URL}hotels`;