import { useEffect, useState } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import RenderStar from './RenderStar';
import InputRange from 'react-input-range';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../endpoints';
import axios from 'axios';

function Filters({ filters, setFilters }) {
    const [facilities, setFacilities] = useState([]);
    const [cities, setCities] = useState([]);
    const [expandedItems, setExpandedItems] = useState(['0', '1', '2', '3', '4', '5', '6']);
    const [rangeSelectedValue, setRangeSelectedValue] = useState({ min: 2680, max: 7600 });
    const handleToggle = (eventKey) => {
        if (expandedItems.includes(eventKey)) {
            setExpandedItems(expandedItems.filter(item => item !== eventKey));
        } else {
            setExpandedItems([...expandedItems, eventKey]);
        }
    };

    const getHotelFacilities = () => {
        axios.get(`${API_BASE_URL}landing/all-facilities`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                setFacilities(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const getCities = () => {
        axios.get(`${API_BASE_URL}landing/all-cities`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                setCities(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getHotelFacilities();
        getCities();
    }, [])

    return (
        <Accordion activeKey={expandedItems} flush className='filter-accordion'>
            <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleToggle("0")}>Star Rating</Accordion.Header>
                <Accordion.Body>
                    <p className='mb-0'><FormCheck checked={filters && filters.stars &&  filters.stars.length > 0 && filters.stars.includes('5')} value={'5'} onChange={(e) => setFilters({ type: 'stars', value: e.target.value })} label={<RenderStar count={5} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                    <p className='mb-0'><FormCheck checked={filters && filters.stars &&  filters.stars.length > 0 && filters.stars.includes('4')} value={'4'} onChange={(e) => setFilters({ type: 'stars', value: e.target.value })} label={<RenderStar count={4} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                    <p className='mb-0'><FormCheck checked={filters && filters.stars &&  filters.stars.length > 0 && filters.stars.includes('3')} value={'3'} onChange={(e) => setFilters({ type: 'stars', value: e.target.value })} label={<RenderStar count={3} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                    <p className='mb-0'><FormCheck checked={filters && filters.stars &&  filters.stars.length > 0 && filters.stars.includes('2')} value={'2'} onChange={(e) => setFilters({ type: 'stars', value: e.target.value })} label={<RenderStar count={2} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                    <p className='mb-0'><FormCheck checked={filters && filters.stars &&  filters.stars.length > 0 && filters.stars.includes('1')} value={'1'} onChange={(e) => setFilters({ type: 'stars', value: e.target.value })} label={<RenderStar count={1} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => handleToggle("1")}>Guest Rating</Accordion.Header>
                <Accordion.Body>
                    <p className="mb-0"><FormCheck value={5} onChange={(e) => setFilters({ type: 'guests', value: e.target.value })} label={<RenderStar count={5} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                    <p className="mb-0"><FormCheck value={4} onChange={(e) => setFilters({ type: 'guests', value: e.target.value })} label={<RenderStar count={4} activeColor={"rgba(176, 209, 43, 1)"} disableColor={"#e5e5e5"} />} /></p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header onClick={() => handleToggle("2")}>Price Rating ($)</Accordion.Header>
                <Accordion.Body>
                    <p>$0-$9,990</p>
                    <div className='d-flex align-items-center justify-content-center' style={{ width: "100%" }}>
                        <InputRange
                            maxValue={9990}
                            minValue={0}
                            formatLabel={value => `${value} $`}
                            value={rangeSelectedValue}
                            onChange={value => setRangeSelectedValue(value)}
                            onChangeComplete={value => setFilters({ type: 'prices', value: value })}
                        />
                    </div>
                    <Button variant='outline-danger' className='btn-custom-color mt-5 text-white'>Filter</Button>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header onClick={() => handleToggle("3")}>Meals</Accordion.Header>
                <Accordion.Body>
                    <p><FormCheck checked={filters && filters.meals && filters.meals.length > 0 && filters.meals.includes('all-inclusive')} onChange={(e) => setFilters({ type: 'meals', value: e.target.value })} value={'all-inclusive'} label="All-inclusive" className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.meals && filters.meals.length > 0 && filters.meals.includes('breakfast-and-dinner-included')} onChange={(e) => setFilters({ type: 'meals', value: e.target.value })} value={'breakfast-and-dinner-included'} label="Breakfast & dinner included" className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.meals && filters.meals.length > 0 && filters.meals.includes('breakfast-included')} onChange={(e) => setFilters({ type: 'meals', value: e.target.value })} value={'breakfast-included'} label="Breakfast Included" className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.meals && filters.meals.length > 0 && filters.meals.includes('kitchen-facilites')} onChange={(e) => setFilters({ type: 'meals', value: e.target.value })} value={'kitchen-facilites'} label="Kitchen facilities" className='text-muted' /></p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header onClick={() => handleToggle("4")}>Facilities</Accordion.Header>
                <Accordion.Body>
                    {facilities && facilities.length > 0 && facilities.map((fac, key) => (
                        <p key={key}>
                            <FormCheck
                                checked={filters && filters.facilities &&  filters.facilities.length > 0 && filters.facilities.includes(fac.id)}
                                value={fac.id}
                                onChange={(e) => setFilters({ type: 'facilities', value: e.target.value })}
                                label={fac.title}
                                className="text-muted"
                            />
                        </p>
                    ))}
                    {facilities && facilities.length > 6 && <Link to={"#"} className='custom-text-color'>Show all {facilities.length}</Link>}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header onClick={() => handleToggle("5")}>Property Type</Accordion.Header>
                <Accordion.Body>
                    <p><FormCheck checked={filters && filters.propertyTypes &&  filters.propertyTypes.length > 0 && filters.propertyTypes.includes('apartments')} value={'apartments'} onChange={(e) => setFilters({ type: 'propertyTypes', value: e.target.value })} label="Apartments" className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.propertyTypes &&  filters.propertyTypes.length > 0 && filters.propertyTypes.includes('bed-and-breakfasts')} value={'bed-and-breakfasts'} onChange={(e) => setFilters({ type: 'propertyTypes', value: e.target.value })} label="Bed and Breakfasts" className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.propertyTypes &&  filters.propertyTypes.length > 0 && filters.propertyTypes.includes('gucci')} value={'gucci'} label="Gucci" onChange={(e) => setFilters({ type: 'propertyTypes', value: e.target.value })} className='text-muted' /></p>
                    <p><FormCheck checked={filters && filters.propertyTypes &&  filters.propertyTypes.length > 0 && filters.propertyTypes.includes('hotels')} value={'hotels'} label="Hotels" onChange={(e) => setFilters({ type: 'propertyTypes', value: e.target.value })} className='text-muted' /></p>
                    {/* <Link to={"#"} className='custom-text-color'>Show all 6</Link> */}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header onClick={() => handleToggle("6")}>City</Accordion.Header>
                <Accordion.Body>
                    {cities && cities.length > 0 && cities.map((city, key) => (
                        <p key={key}>
                            <FormCheck 
                                checked={filters && filters.cities &&  filters.cities.length > 0 && filters.cities.includes(city)}
                                onChange={(e) => setFilters({ type: 'cities', value: e.target.value })} 
                                value={city} 
                                label={city} 
                                className='text-muted' 
                            />
                        </p>
                    ))}
                    {cities && cities.length > 6 && <Link to={"#"} className='custom-text-color'>Show all {cities.length}</Link>}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const mapStateToProps = (state) => ({
    filters: state.landingPagesInitialState.filters
});

const dispatchStateToProps = (dispatch) => ({
    setFilters: (obj) => {
        dispatch({
            type: "UPDATE_FILTERS",
            payload: obj
        })
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(Filters);