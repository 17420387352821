import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { tours } from "../../appData";
import { Link } from "react-router-dom";
import SearchResultSingleItem from "../includes/SearchResultSingleItem";
import { BiFilterAlt } from "react-icons/bi";

export default function Tour() {
    return (
        <Container className="tour-container">
            <Row className="mb-2">
                <Col xs={12} md={6}>
                    <h5>Attraction Tickets: 12 results found</h5>
                </Col>
                <Col xs={12} md={6} style={{ textAlign: "right" }}>
                    <div className="d-flex justify-content-end align-items-center">
                        <Dropdown>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                Sort by Latest {" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>Sort by Latest</Dropdown.Item>
                                <Dropdown.Item>Sort by Oldest</Dropdown.Item>
                                <Dropdown.Item>Sort by Name</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="danger" size="sm"><BiFilterAlt /> Show Filters</Button>
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                {tours.map((tour, key) => {
                    return (
                        <Col xs={6} md={3} key={key} className="mb-3">
                            <Link to={"/hotel-detail"} className="text-decoration-none">
                                <SearchResultSingleItem imgUrl={tour.thumbnail} requestFrom='tour' item={tour} />
                            </Link>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}