import { Button, Col, Dropdown, FormCheck, Image, Row, Table } from "react-bootstrap";
import { BiSolidSortAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { API_REQUEST_HEADER, DELETE_EMPLOYEE_ENDPOINT, GET_EMPLOYEE_ENDPOINT } from "../../../../src/endpoints";
import axios from "axios";
import MyPagination from "../rooms/room-pagination";
import { cutomDateTimeFormat, getDayNamesAndTimes } from "../../../../src/helpers/common-helper";
import Add from "./add";
import Loading from "../../loading";
import ConfirmDelete from "../booking/confirm-delete";

export default function Employee() {
    const [loading, setLoading] = useState(true);
    const [tabActiveKey, setTabActiveKey] = useState('allEmployee');
    const [showAddModal, setAddShowModal] = useState(false);
    const [employeeList, setEmployeeList] = useState({});
    let userId = JSON.parse(localStorage.getItem("user"))?.id;
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterStatus, setFilterStatus] = useState('all');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedEmpIDForDelete, setSelectedEmpIDForDelete] = useState(null);
    useEffect(() => {
        fetchApiData(userId, pageLimit, selectedPageNumber);
    }, [selectedPageNumber, userId, pageLimit, showAddModal]);

    const fetchApiData = (userId, limit, page) => {
        setLoading(true);
        axios.get(GET_EMPLOYEE_ENDPOINT(userId, limit, page), API_REQUEST_HEADER()).then((response) => {
            if (response?.status === 200) {
                setEmployeeList(response.data);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        });
    }

    const handlePageChange = (pageNumber) => {
        fetchApiData(userId, pageLimit, pageNumber);
        setSelectedPageNumber(pageNumber);
    };

    const deleteEmployee = () => {
        axios.delete(`${DELETE_EMPLOYEE_ENDPOINT}/${selectedEmpIDForDelete}`, API_REQUEST_HEADER()).then((response) => {
            if (response.status === 204) {
                setShowConfirmModal(false);
                fetchApiData(userId, pageLimit, selectedPageNumber);
            }
        }).catch((err) => {
            console.log(err?.response?.data?.message);
        })
    }

    const confirmDeleteEmployee = (id) => {
        setSelectedEmpIDForDelete(id);
        setShowConfirmModal(true);
    }

    return (
        <>
            {loading ? <Loading />
                :
                <>
                    <Row className="mb-3">
                        <Col xs={12} md={9} className="d-flex align-items-center">
                            <ul className="guest-tabs">
                                <li className={tabActiveKey === 'allEmployee' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('allEmployee'); setFilterStatus('all') }}>All Employee ({employeeList?.totalResults})</Link></li>
                                <li className={tabActiveKey === 'active' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('active'); setFilterStatus('active') }}>Active Employee</Link></li>
                                <li className={tabActiveKey === 'disable' ? 'active' : ''}><Link to={"#"} onClick={() => { setTabActiveKey('disable'); setFilterStatus('disable') }}>Inactive Employee</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={3} className="d-flex align-items-center justify-content-end">
                            <Button variant="danger" className="btn-custom-color add-new-room-btn btn-lg fontSize-14" onClick={() => setAddShowModal(true)}><AiOutlinePlus /> Add New Employee</Button>
                        </Col>
                    </Row>

                    <div className="guest-tabs-contents">
                        <Table striped responsive hover style={{ backgroundColor: "white" }} border={"0px #e5e5e5"}>
                            <thead>
                                <tr>
                                    <th><FormCheck /></th>
                                    <th><div className="d-flex align-items-center">Employee Name <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Job Desk <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Schedule <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Contact <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th><div className="d-flex align-items-center">Status <BiSolidSortAlt color="#D3D6E4" style={{ marginLeft: 5 }} /></div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeList && employeeList.results && employeeList.results.length > 0
                                    ? employeeList.results
                                        .filter((emp) => {
                                            return (
                                                (filterStatus === 'all') ||
                                                (filterStatus === 'active' && emp.status && emp.status.toLowerCase() === 'active') ||
                                                (filterStatus === 'disable' && emp.status && emp.status.toLowerCase() === 'disable')
                                            );
                                        })
                                        .map((emp, key) => (
                                            <tr key={key}>
                                                <td style={{ verticalAlign: 'middle' }}><FormCheck /></td>
                                                <td style={{ width: "250px", verticalAlign: 'middle' }}>
                                                    <div className="d-flex align-items-center">
                                                        <Image src={emp.avatar} className="me-2" width={60} height={60} rounded />
                                                        <div className="d-flex" style={{ flexDirection: "column", marginLeft: 5 }}>
                                                            <Link to={"#"} className="custom-text-color">{emp.code}</Link>
                                                            <strong>{emp.name}</strong>
                                                            <small className="fontSize-12">Join on {cutomDateTimeFormat('date', emp.joiningDate, false, false)}</small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ maxWidth: 300, padding: 15, verticalAlign: 'middle' }}>
                                                    {emp.jobDesk}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                                        <strong>{getDayNamesAndTimes(emp.checkIn, emp.checkOut, true)}</strong>
                                                        <span>{getDayNamesAndTimes(emp.checkIn, emp.checkOut, false)}</span>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <div className="d-flex">
                                                        <Image src="/images/telephone.svg" /> &nbsp;
                                                        <Link to={"#"} className="custom-text-color">{emp.phoneNumber}</Link>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }}><span className={`text-${emp.status && emp.status.toLowerCase()} font-12`}>{emp.status ? emp.status : '-'}</span></td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="more-vertical"><BsThreeDots color="black" /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                                                <Dropdown.Item onClick={() => confirmDeleteEmployee(emp.userId)}>Delete</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : <tr><td colSpan={7} className="text-center text-danger">No record found</td></tr>}
                            </tbody>
                        </Table>
                        <MyPagination list={employeeList?.results} totalPages={employeeList?.totalPages} page={employeeList?.page} pageLimit={pageLimit} totalResults={employeeList?.totalResults} onPageChange={handlePageChange} />
                    </div>

                    <Add show={showAddModal} onHide={() => setAddShowModal(false)} />
                    <ConfirmDelete
                        textBody="Are you sure you want to delete this employee?"
                        show={showConfirmModal}
                        onHide={() => setShowConfirmModal(false)}
                        delete={deleteEmployee}
                    />
                </>
            }
        </>
    );
}