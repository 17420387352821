import { Card, Col, FormGroup, FormLabel, ProgressBar, Row } from "react-bootstrap";

const AverageReview = ({ reviews, averageReviews }) => {

    return (
        <Row>
            <Col xs={12} md={4}>
                <Card>
                    <Card.Body>
                        <div className='d-flex flex-direction-column'>
                            <div>
                                <span className="average-review-count">{averageReviews?.rating.toFixed(1)}/</span>
                                <sub className="average-review-count-total">5</sub>
                            </div>
                            <span className="average-review-title">
                                {averageReviews?.rating === 5
                                    ? 'Exceptional'
                                    : averageReviews?.rating === 4
                                        ? 'Excellent'
                                        : averageReviews?.rating === 3
                                            ? 'Very Good'
                                            : averageReviews?.rating === 2
                                                ? 'Good'
                                                : averageReviews?.rating === 1
                                                    ? 'Poor'
                                                    : 'Beginner'
                                }
                            </span>
                            <span className='text-muted'>From {reviews?.length} Review</span>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={8}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Cleanliness</FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.cleanlinessRating * 20} label={`${averageReviews?.cleanlinessRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.cleanlinessRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Facilities</FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.facilitiesRating * 20} label={`${averageReviews?.facilitiesRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.facilitiesRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Location</FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.locationRating * 20} label={`${averageReviews?.locationRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.locationRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Service</FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.serviceRating * 20} label={`${averageReviews?.serviceRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.serviceRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Value for money</FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.valueForMoneyRating * 20} label={`${averageReviews?.valueForMoneyRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.valueForMoneyRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <FormLabel className='text-muted fontWeight-400 mb-1'>Room comfort and quality </FormLabel>
                            <Row>
                                <Col xs={10} md={10} style={{ display: "grid", alignItems: "center" }}><ProgressBar now={averageReviews?.roomComfortAndQualityRating * 20} label={`${averageReviews?.roomComfortAndQualityRating}%`} variant="custom-variant" /></Col>
                                <Col xs={2} md={2}><span className="custom-text-color fontWeight-600">{averageReviews?.roomComfortAndQualityRating.toFixed(1)}</span></Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

};

export default AverageReview;