import { Link, Outlet, useLocation } from "react-router-dom";

import Menu from "../includes/menu";
import { Button, Col, Container, FormControl, Image, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import FooterDivider from "../includes/FooterDivider";
import CartIconWithBadge from "../includes/CartIconWithBadge";

export default function LandingLayout(props) {
    const location = useLocation();
    const { pathname } = location;
    return (
        <>
            <Navbar bg="dark" expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand href="/" className="d-lg-none">
                        <Image src={'./../logo-white.svg'} />
                        <Image src={'./../logo-text-white.svg'} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="headerNavbarScroll" />
                    <Navbar.Collapse id="headerNavbarScroll">
                        <Nav className="me-auto" style={{ maxHeight: '100px' }} navbarScroll>
                            <div className="d-flex align-items-center top-header--search-container">
                                <div className="top-header--search-inner-container">
                                    <FormControl type="text" size="sm" placeholder="Tour, hotel, etc" className="top-header--search-input" />
                                    <div className="top-header--search-input-icon"><FaSearch color="#e5e5e5" /></div>
                                </div>
                            </div>
                        </Nav>
                        <div className="top-header--right-social-links-and-others"> {/* Use Bootstrap classes */}
                            <Nav className="top-header--social-links">
                                <Nav.Link><img src="./../images/facebook-white.png" alt="facebook" /></Nav.Link>
                                <Nav.Link><img src="./../images/twitter-white.png" alt="twitter" /></Nav.Link>
                                <Nav.Link><img src="./../images/instagram-white.png" alt="instagram" /></Nav.Link>
                                <Nav.Link><img src="./../images/linkedin-white.png" alt="linkedin" /></Nav.Link>
                            </Nav>
                            <Nav.Link className="signin-or-register-link">
                                <Link to="/login" className="top-header--sign-btn" style={{ textDecoration: "none", color: "white" }}>Sign in</Link>
                                <span className="d-none d-lg-block d-md-block me-2 ml-2">{" or "}</span>
                                <Link to="/signup" className="top-header--register-btn" style={{ textDecoration: "none", color: "white" }}>Register</Link>
                            </Nav.Link>

                            <NavDropdown
                                id="nav-dropdown-currency"
                                title="EUR"
                                align={{ lg: 'end' }}
                            >
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                        <Nav className="me-auto d-lg-none">
                            <Nav.Link as={Link} to="/" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/home' ? 'active' : ''}`}>Home</Nav.Link>
                            <Nav.Link as={Link} to="/all-hotels" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/all-hotels' ? 'active' : ''}`}>Hotel</Nav.Link>
                            <Nav.Link as={Link} to="/tour" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/tour' ? 'active' : ''}`}>Tour</Nav.Link>
                            <Nav.Link as={Link} to="/about-us" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/about-us' ? 'active' : ''}`}>About Us</Nav.Link>
                            <Nav.Link as={Link} to="/contact-us" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/contact-us' ? 'active' : ''}`}>Contact Us</Nav.Link>
                            <Nav.Link as={Link} to="/cart" className={`nav-link ${pathname === '/about-us' || pathname === '/contact-us' ? 'text-white ' : ''}${pathname === '/cart' ? 'active' : ''}`}>
                                <CartIconWithBadge itemCount={5} />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Menu />
            <div style={{ background: "rgba(70, 15, 31, 0.04)" }}>
                <Outlet />
            </div>
            <footer className="bg-dark text-white" id="myfooter">
                <Container style={{ paddingTop: 25, paddingBottom: 15 }}>
                    <Row>
                        <Col xs={12} md={3}>
                            <h6 className="mb-4">Need My Travel Help?</h6>
                            <Row>
                                <Col xs={2} md={2}>
                                    <img src="./../images/phone-dailer.svg" width={40} alt="dailer" />
                                </Col>
                                <Col xs={10} md={10}>
                                    <Row>
                                        <Col>Got Questions ? Call us 24/7!</Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="custom-text-color">Call Us:</span> <Link to={"tel:+612 8764 8924"} className="custom-text-color">+61 2 8764 8924</Link></Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="custom-text-color">Or Whatsapp:</span> <Link to={"tel:+61452 373 009"} className="custom-text-color">+61 452 373 009</Link></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <h6>Contact Info</h6>
                            <p>7 Greenfield Parade<br />Bankstown, NSW, Australia.</p>
                            <div className="d-flex align-items-center mb-5">
                                <Button variant="outline-light" className="rounded-circle me-2"><Image src="./../images/facebook-white.png" width={12} height={12} /></Button>
                                <Button variant="outline-light" className="rounded-circle me-2"><Image src="./../images/twitter-white.png" width={12} height={12} /></Button>
                                <Button variant="outline-light" className="rounded-circle me-2"><Image src="./../images/instagram-white.png" width={12} height={12} /></Button>
                                <Button variant="outline-light" className="rounded-circle me-2"><Image src="./../images/linkedin-white.png" width={12} height={12} /></Button>
                            </div>
                        </Col>
                        <Col xs={12} md={2}>
                            <h6 className="mb-4">Company</h6>
                            <ul className="custom-footer-list">
                                <li><Link to={"/"}>Home</Link></li>
                                <li><Link to={"/about-us"}>About Us</Link></li>
                                <li><Link to={"#"}>Careers</Link></li>
                                <li><Link to={"#"}>Term of Use</Link></li>
                                <li><Link to={"#"}>Privacy Statement</Link></li>
                                <li><Link to={"#"}>Give Us Feedbacks</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={2}>
                            <h6 className="mb-4">Other Services</h6>
                            <ul className="custom-footer-list">
                                <li><Link to={"#"}>Investor Relations</Link></li>
                                <li><Link to={"#"}>Rewards Program</Link></li>
                                <li><Link to={"#"}>PointsPLUS</Link></li>
                                <li><Link to={"#"}>Partners</Link></li>
                                <li><Link to={"#"}>List My Hotel</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={2}>
                            <h6 className="mb-4">Support</h6>
                            <ul className="custom-footer-list">
                                <li><Link to={"#"}>Account</Link></li>
                                <li><Link to={"#"}>Legal</Link></li>
                                <li><Link to={"#"}>Contact</Link></li>
                                <li><Link to={"#"}>Affiliate Program</Link></li>
                                <li><Link to={"#"}>Privacy Policy</Link></li>
                            </ul>
                        </Col>
                        <Col xs={12} md={3}>
                            <h6 className="mb-4">Mailing List</h6>
                            <p>
                                Sign up for our mailing list to get
                                <br />latest updates and offers
                            </p>
                            <Row>
                                <Col xs={12} md={7} className="custom-right-padding-zero">
                                    <FormControl type="email" placeholder="Your Email" className="custom-email-form-control" />
                                </Col>
                                <Col xs={12} md={5}>
                                    <div className="d-grid gap-2">
                                        <Button variant="danger" size="lg" className="subscribes-btn">
                                            Subscribes
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <FooterDivider />
                <Container>
                    <Row>
                        <Col xs={12} md={7}>
                            <Image src="./../logo-white.svg" />
                            <Image src="./../logo-text-white.svg" />
                        </Col>
                        <Col xs={12} md={5}>
                            <Image src="./../images/credit-cards.svg" />
                        </Col>
                    </Row>
                </Container>

                <FooterDivider />

                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <p>&copy; 2022 TravelPinq. All rights reserved.</p>
                        </Col>
                        <Col xs={12} md={6}>
                            <ul className="footer-bottom-list">
                                <li><Link to={"/"}>Home</Link></li>
                                <li><Link to={"#"}>Hotel</Link></li>
                                <li><Link to={"#"}>Tour</Link></li>
                                <li><Link to={"#"}>Activity</Link></li>
                                <li><Link to={"#"}>Rental</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </footer>
        </>
    );
}